
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    job: Object,
    olps: Array
  },
  setup (props) {
    onMounted(async () => {})

    const getAlreadyNum = () => {
      var n = 0
      if(props.olps == undefined) {
        return 0
      }
      props.olps.forEach((o:any) => {
        n += parseInt(o['stock'])
      })
      return n
    }

    return {getAlreadyNum}
      
  }
})
