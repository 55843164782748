
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    currentMenu: String,
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)

    const toJumpPage = (s: string) => {
      router.push({ name: s })
    }

    const isCurrentMenu = (s: string) => {
      return (props.currentMenu == s)? true : false
    }

    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })
    return {
      user, org, toJumpPage, isCurrentMenu
    }
  }
})

