
import { defineComponent, onMounted, inject, computed, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
import axios from 'axios'
import moment from 'moment'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Swal from 'sweetalert2'

export default defineComponent({
  components: { Field, Form, ErrorMessage, Header, SideBar},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      selectedCard: "",
      selectedPlan: "",
      changePlan: "",
      isAddCard: false,
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: ""
    })
    const schema = yup.object({
      card_number: yup.string().required("カード番号は必須です"),
      card_cvc: yup.string().required("CVCコードは必須です"),
      card_month: yup.string().required("有効期限は必須です"),
      card_year: yup.string().required("有効期限は必須です"), 
    });

    const planDict = {
      "price_1L01T4GhouLonqYfiv7drttp": "無料契約プラン",
      "price_1L01RFGhouLonqYfec98SKNk": "倉庫保管プラン(契約満期時に1ヶ月自動更新プラン)",
      "price_1JtU67GhouLonqYfmsiPetlT": "ワンショットプラン(契約満期時に利用できなくなります)",
      "price_1JtU59GhouLonqYfZurbVHiL": "有料プラン(契約満期時に3ヶ月自動更新プラン)",
      "price_1JtU59GhouLonqYfEg8AVIcr": "有料プラン(契約満期時に6ヶ月自動更新プラン)",
      "price_1JtU59GhouLonqYflsN51Gk0": "有料プラン(契約満期時に1年自動更新プラン)",
    }
    const planLabel = {
      "price_1L01T4GhouLonqYfiv7drttp": "無料契約プラン",
      "price_1L01RFGhouLonqYfec98SKNk": "倉庫保管プラン",
      "price_1JtU67GhouLonqYfmsiPetlT": "ワンショットプラン",
      "price_1JtU59GhouLonqYfZurbVHiL": "有料プラン/ 3ヶ月更新",
      "price_1JtU59GhouLonqYfEg8AVIcr": "有料プラン/ 6ヶ月更新",
      "price_1JtU59GhouLonqYflsN51Gk0": "有料プラン/ 1年新",
    }
    const planAt  = {
      "price_1L01T4GhouLonqYfiv7drttp": 1,
      "price_1L01RFGhouLonqYfec98SKNk": 1,
      "price_1JtU59GhouLonqYfZurbVHiL": 3,
      "price_1JtU59GhouLonqYfEg8AVIcr": 6,
      "price_1JtU59GhouLonqYflsN51Gk0": 12,
    }
    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)
    const plan = computed(() => store.state.user.plan)
    const card_tokens = computed(() => store.state.card_token.card_tokens)

    watchEffect(() => {
      state.selectedPlan = org.value.plan_id
      state.selectedCard = org.value.card_token_id
      state.changePlan = state.selectedPlan
    })
    onMounted(async () => {
      store.dispatch('user/fetchUser')
      store.dispatch('card_token/fetchCardTokens')

    })
    const cancel = () => {
      router.push({ name: "Settings" })
    }

    const dateFormat = (s:string) => {
      return moment(s).format("Y.M.D")
    }
    const planEndAt = (s:string, n:number) => {
      return moment(s).add(n, "months").format("Y.M.D")
    }
    const changeCardId = (s:string) => {
      state.selectedCard = s
    }
    const applyPlan = () => {
      const idToken: string | null = localStorage.getItem("idToken")
      const params = {
        plan_id: state.changePlan,
        card_token_id: state.selectedCard
      }
      if(state.selectedPlan == state.changePlan) {
        alert("既にこのプランには加入しています")
        return;
      }
      if(state.selectedCard == "") {
        alert("カードを選択してください")
      } else {
        Swal.fire({
          title: 'プランを変更しますか？',
          text: "変更すると、請求が開始されます",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ea0e1',
          cancelButtonColor: '#ed3773',
          confirmButtonText: '変更する',
          cancelButtonText: 'キャンセル'
        }).then((result) => {
          if(result.isConfirmed) {
            axios
              .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/subscriptions/1", params, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
              .then((res:any)  => {
                console.log(res)
              })
          }
        })


      }

    }

    const addCardToken = () => {
      const idToken: string | null = localStorage.getItem("idToken")
      const card = {
        number: state.cardNumber,
        month: state.cardMonth,
        year: state.cardYear,
        cvc: state.cardCvc
      }
      const headers = {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        }
      }
      Swal.fire({
        title: 'カードを変更しますか？',
        text: "変更すると、現在登録されているカード情報は削除されます",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ea0e1',
        cancelButtonColor: '#ed3773',
        confirmButtonText: '変更する',
        cancelButtonText: 'キャンセル'
      }).then((result) => {
        if(result.isConfirmed) {
          axios
            .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/stripe", {card: card}, headers)
            .then((res:any) => {
              console.log(res.data)
              const ct = res.data.id
              const c = {card_token: ct, tokens: res.data.card.id, number: res.data.card.last4, brand: res.data.card.brand, expire_year: state.cardYear, expire_month: state.cardMonth}
              store.dispatch('card_token/createCardToken', c)
                .then(() => {
                  state.isAddCard = false
                  state.cardNumber = ""
                  state.cardMonth = ""
                  state.cardYear = ""
                  state.cardCvc = ""
                  store.dispatch('card_token/fetchCardTokens')
                })
            }).catch(() => {
              alert("エラーが起こりました。")
            })
        }
      })





    }


    const logout = () => {
      auth.logout({
        returnTo: window.location.origin,
      })
    }
    return {
      logout, user, org, cancel, state, applyPlan, planDict, card_tokens, planLabel, plan, dateFormat, planEndAt, addCardToken,
      schema, changeCardId, planAt
    }
  }
})

