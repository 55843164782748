
<template>
  <div class="home">

    <Header></Header>
    <!-- Main -->
    <main class="main container p-0">
      <div class="row">
        <!-- Left field (side bar) -->
        <div class="col-lg-3 left-field">
          <SideBar :currentMenu="'plan'"></SideBar>
        </div>
        <!-- Right field (content) -->
        <div class="col-lg-9 right-field">
          <section class="tab-content">
            <!-- Page 9 -->
            <section class="tab-pane active" id="plan" role="tabpanel" aria-labelledby="plan-tab">
              <!-- Information Form -->
              <h6 class="weight-h6-bold">退会手続き</h6>
              <section class="account-information-content-region">
                <div class="cus-blanc-mr blanc-plan-text">
                  <h6 class="weight-h6-bold">案件進行中の退会は全てお客様都合の取り消しになります。</h6>
                  <h6 class="weight-h6-bold"><span class="color-red">退会後は下記全てのアカウント履歴が確認できなくなります</span >のでご注意ください。</h6>
                  <ul class="pl-1" style="margin-top: 23px;">
                    <li class="break-text">
                      <div class="d-flex align-items-center">
                        <span class="bullet-dot"></span>
                        <span>進行中の案件詳細</span>
                      </div>
                    </li>
                    <li class="break-text">
                      <div class="d-flex align-items-center">
                        <span class="bullet-dot"></span>
                        <span>下書中の案件詳細</span>
                      </div>
                    </li>
                    <li class="break-text">
                      <div class="d-flex align-items-center">
                        <span class="bullet-dot"></span>
                        <span>終了した案件詳細</span>
                      </div>
                    </li>
                    <li class="break-text">
                      <div class="d-flex align-items-center">
                        <span class="bullet-dot"></span>
                        <span>御社アカウントにいいね!してくれたアカウント一覧</span>
                      </div>
                    </li>
                    <li class="break-text">
                      <div class="d-flex align-items-center">
                        <span class="bullet-dot"></span>
                        <span>お支払い履歴／領収書発行</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="plain-text-wrapper">
                  <div>進行中の案件は、商品発送前の場合、 契約期間終了後にアカウントを消去いたします。</div>
                  <div>倉庫に商品在庫がある場合はインフルエンサーへの送料を以って御社宛に商品を返品いたします。 </div>
                  <div>インフルエンサーに商品を発送済の場合は、</div>
                  <div> お客様都合で案件取り消しが通知され、商品は譲渡となります。</div>
                </div>
                <h6 class="color-blue">倉庫に在庫がある方は、案件を再開するまでの間、倉庫に保管するための「倉庫保管プラン」が、</h6>
                <h6 class="color-blue">また、過去の案件を無料で確認できる 「無料契約」 プランもございます。</h6>
                <!-- Package Confirmation Table -->
                <div class="package-confirmation-table">
                  <table>
                    <tr>
                      <th></th>
                      <th class="text-center">有料定額</th>
                      <th class="text-center">倉庫保管</th>
                      <th class="text-center">無料契約</th>
                      <th class="text-center">アカウント削除</th>
                    </tr>
                    <tr>
                      <td>案件の新規作成/下書き/進行</td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td class="x-symbol">×</td>
                      <td class="x-symbol">×</td>
                      <td class="x-symbol">×</td>
                    </tr>
                    <tr>
                      <td>終了した案件の確認</td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td class="x-symbol">×</td>
                    </tr>
                    <tr>
                      <td>御社にいいね!したアカウントの照会</td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td class="x-symbol">×</td>
                    </tr>
                    <tr>
                      <td>支払い履歴の照会</td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td class="x-symbol">×</td>
                    </tr>
                    <tr>
                      <td>領収書発行</td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td class="x-symbol">×</td>
                    </tr>
                    <tr>
                      <td>商品倉庫保管</td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td>
                        <div class="o-symbol"></div>
                      </td>
                      <td class="x-symbol">×</td>
                      <td class="x-symbol">×</td>
                    </tr>
                  </table>
                </div>
                <div class="pair-card-container">
                  <!-- Blanc Storage -->
                  <div class="blanc-storage-container">
                    <div class="fixed-pay-rate">
                      <div class="fixed-pay-rate__title">
                        <span style="text-decoration: unset;">倉庫保管プラン</span>
                      </div>
                      <div class="blanc-storage__text">
                        <div>入庫後に案件を中止したり、</div>
                        <div>余った商品を保管するだけのプランです。</div>
                        <div>案件稼働がないけれど</div>
                        <div>商品の返品を希望されない方向け。</div>
                        <div class="invisible cus-mb">案件稼働がないけれど</div>
                        <div class="invisible ">商品の返品を希望。</div>
                      </div>
                      <div class="blanc-storage__selection">
                        <div class="row mx-0 justify-content-center">
                          <div class="col-auto d-flex align-items-center px-0">
                            <input type="checkbox" id="blanc-storage" name='blanc-selection' checked="checked">
                            <label class="mb-0" for="blanc-storage">1ヶ月更新</label><br>
                          </div>
                          <div class="col-auto pr-0">800円/1ヶ月</div>
                        </div>
                      </div>
                      <!-- Confirmation Button -->
                      <div class="wrapper-confirm-button">
                        <div class="confirm-button ">
                          <button @click="applyPlan(wh_storage_plan)" class="fixed-pay-rate__button confirm-button--right ">プランを変更</button>
                        </div>
                      </div>
                      <div class="blanc-text-wrapper">
                        <div class="blanc-storage__text-small">
                          <div>※別途送料(1名あたり1000円)あり</div>
                          <div>※契約期間中の途中解約はできません。</div>
                          <div>※契約満期時に自動でプランが更新されます。</div>
                          <div>（更新時にプラン変更が可能です）</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Free Package -->
                  <div class="blanc-storage-container">
                    <div class="fixed-pay-rate">
                      <div class="fixed-pay-rate__title">
                        <span style="text-decoration: unset;">無料契約プラン</span>
                      </div>
                      <div class="blanc-storage__text" style="margin-bottom: 54px;">
                        <div>継続的に案件を作成しないが</div>
                        <div>過去の案件などの照会は可能なプラン。</div>
                        <div class="invisible no-element">案件稼働がないけれど</div>
                        <div>無料プランに変更した際に、</div>
                        <div> 倉庫に商品がある場合は</div>
                        <div>インフルエンサー宛の送料を利用して</div>
                        <div class="mb-0">商品が自動的に返送されます。 </div>
                      </div>
                      <!-- Confirmation Button -->
                      <div class="wrapper-confirm-button">
                        <div class="confirm-button ">
                          <button @click="applyPlan(free_plan)" class="fixed-pay-rate__button confirm-button--right ">プランを変更</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 class="mb-5 text-center weight-h6-bold">本当に退会されますか？</h6>
                <!-- Confirmation Button -->
                <div class="wrapper-confirm-button mb-0">
                  <div class="confirm-button">
                    <button class="confirm-button--left">退会する</button>
                  </div>
                  <div class="confirm-button">
                      <a href="/settings"><button class="confirm-button--right">キャンセル</button></a>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      </div>
    </main>

  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios"
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
import Swal from 'sweetalert2'

export default {
  name: "Dashboard",
  data() {
    return {
      idToken: localStorage.getItem("idToken"),
      org: {},
      selectedCard: "",
      selectedPlan: "",
      wh_storage_plan: "price_1L01RFGhouLonqYfec98SKNk",
      free_plan: "price_1L01T4GhouLonqYfiv7drttp"

    }
  },
  components: {Header, SideBar},
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    applyPlan(p) {
      const params = {
        plan_id: p,
        card_token_id: this.selectedCard
      }
      if(this.selectedPlan == p) {
        alert("既にこのプランには加入しています")
        return;
      }
      Swal.fire({
        title: 'プランを変更しますか？',
        text: "変更すると、請求が開始されます",
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#1ea0e1',
        cancelButtonColor: '#ed3773',
        confirmButtonText: '変更する',
        cancelButtonText: 'キャンセル'
      }).then((result) => {
        if(result.isConfirmed) {
          axios
            .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/subscriptions/1", params, {headers: { Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json"}})
            .then((res)  => {
              console.log(res)
            })
        }
      })


    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/organizations/1", {headers: { Authorization: `Bearer ${this.idToken}`, "Content-Type": "application/json"}})
      .then((res) => {
        this.org = res.data.org
        this.selectedCard = this.org.card_token_id
        this.selectedPlan = this.org.plan_id
      })

  },
}
</script>

<style scoped lang="scss">
    .left-field {
      background: inherit;
    }

    .account-information-content-region {
      background-color: white;
      padding: 57px 50px 7.5rem 50px;
    }

    /* Blanc Plan */
    .blanc-plan-text {
      margin-bottom: 5.25rem;
    }

    .blanc-plan-text li {
      margin-bottom: 0.25rem;
      font-weight: var(--font-weight-lighter);
    }

    .blanc-plan-text .break-text {
      display: flex;
    }

    .blanc-plan-text .bullet-dot {
      border-radius: 50%;
      margin-right: 0.25rem;
      width: 3px;
      height: 3px;
      background: #000;
    }

    .blanc-text__note {
      position: relative;
      right: 10px;
      margin-top: 1rem;
      font-weight: var(--font-weight-lighter);
    }

    .plain-text-wrapper {
      font-weight: var(--font-weight-lighter);
      margin-bottom: 98px;
    }

    .plain-text-wrapper div:not(:last-child) {
      margin-bottom: 4px;
    }

    /* Confirm Button Style */

    .wrapper-confirm-button {
      margin-bottom: 36px;
    }


    .confirm-button--left {
      background-color: white;
      margin-right: 1rem;
    }

    .confirm-button--right {
      background-color: var(--default-yellow-color);
    }

    .fixed-pay-rate__button {
      width: unset !important;
      padding: 0.9rem 2.25rem !important;
      border: none !important;
      color: white;
      background-image: linear-gradient(to right, #4893e6, #75ccc5);
      font-size: 0.9rem;
    }

    /* Package Conformation Table */

    .package-confirmation-table {
      margin-top: 2rem;
      margin-bottom: 40px;
    }

    .package-confirmation-table table {
      border-collapse: collapse;
      width: 100%;
    }

    .package-confirmation-table th,
    td {
      border: 1px solid black;
      text-align: left;
      padding: 8px 14px;
    }

    .package-confirmation-table table tbody tr td:first-child {
      font-weight: var(--font-weight-bold);
    }

    .o-symbol {
      border: 1px solid black;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      margin: 0 auto;
    }

    .x-symbol {
      font-size: 1.8rem;
      padding: 0px;
      font-weight: var(--font-weight-lighter);
      text-align: center;
    }

    /* Fixed Pay Rate */

    .fixed-pay-rate-container {
      border: 4px solid #e4e4e4;
      border-radius: 12px;
      min-width: 506px;
    }

    .fixed-pay-rate {
      letter-spacing: 1px;
      font-size: 1.1rem;
    }

    .fixed-pay-rate__title {
      margin-top: 2.4375rem;
      margin-bottom: 2.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .fixed-pay-rate__title span {
      font-size: 1.6rem;
      font-weight: var(--font-weight-bolder);
      text-decoration: underline;
      text-decoration-color: #60b7d5;
    }


    .fixed-pay-rate__check .stick-icon {
      position: relative;
      transform: rotate(45deg);
      width: 12px;
      height: 24px;
      border-bottom: 3px solid #616161;
      border-right: 3px solid #616161;
      bottom: 2px;
    }

    .fixed-pay-rate__selection {
      display: flex;
    }

    .fixed-pay-rate__selection>div {
      padding-left: 48px;
    }

    .selection-title {
      padding: 0 1.1875rem 0 0;
    }

    .selection-wrapper .row {
      padding: 0;
      margin-bottom: 8px;
    }

    .selection-line {
      display: flex;
      align-items: center;
      padding-right: 1.1875rem;
      padding-left: 0;
      min-width: 85.1833px;
    }

    .fixed-pay-rate__selection label {
      margin-bottom: 0;
      margin-left: 0.5rem;
    }

    .fixed-pay-rate__selection input[type="radio"],
    input[type="checkbox"] {
      cursor: pointer;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      font: inherit;
      width: 1.15em;
      height: 1.15em;
      border: 1px solid #8e8e8e;
      border-radius: 50%;
      outline: none;
      margin-right: 0.5rem;
      box-shadow: inset 0 0 0 3px #FFF;
    }

    .fixed-pay-rate__selection input[type=radio]:checked,
    input[type="checkbox"]:checked {
      background-image: linear-gradient(to right, var(--default-blue-color), var(--default-emerald-green-color));
    }

    .fixed-pay-rate__button {
      border: none !important;
      color: white;
      background-image: linear-gradient(to right, #4893e6, #75ccc5);
      width: 200px !important;
      font-size: 1rem;
    }


    /* Blanc Storage */

    .pair-card-container {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      margin-bottom: 175px;
    }

    .blanc-storage-container {
      width: 49%;
      border: 3px solid #e4e4e4;
      border-radius: 12px;
      min-width: 264px;
    }

    .blanc-storage__text div {
      text-align: center;
      letter-spacing: normal;
      font-size: 1rem;
      margin-bottom: 7px;
      font-weight: var(--font-weight-normal);
    }

    .blanc-text-wrapper {
      font-size: 0.7rem;
      margin-bottom: 28px;
      text-align: center;
    }

    .blanc-storage__text-small {
      display: inline-block; 
      text-align: left;
      font-weight: var(--font-weight-normal);
    }

    .blanc-storage__selection {
      width: 100%;
      margin-bottom: 52px;
      font-size: 1.1rem;
    }

    .blanc-storage__button {
      border: none !important;
      color: white !important;
      background-color: #c9c9c9;
      width: 200px !important;
    }


    /* Custom Style */

    .mb-6 {
      margin-bottom: 6rem;
    }

    .cus-blanc-mr {
      margin-bottom: 40px;
    }

    .color-red {
      color: red;
    }

    .color-blue {
      color: var(--default-blue-color);
      letter-spacing: -1px;
      font-weight: var(--font-weight-bold);
    }

    /* Reponsive */

    @media (max-width: 1599.98px) {

      .blanc-plan-text .break-text {
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: 1199.98px) {
      .account-information-content-region {
        padding: 2rem;
      }
    }

    @media (max-width: 991.98px) {
      .account-information-content-region {
        padding: 1rem;
      }

      .wrapper-confirm-button {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .confirm-button--left {
        margin-right: 0rem;
        margin-bottom: 1.5rem;
      }
    }

    @media (max-width: 767.98px) {

      .pair-card-container {
        flex-direction: column;
        align-items: center;
      }

      .blanc-storage-container {
        width: 100%;
        margin-bottom: 1.5rem;
      }
    }

    @media (max-width: 343.98px) {}
</style>
