
import { defineComponent, reactive, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment'

interface State {
  state: string;
  job_uuid: string | string[];
  selectedIds: any;
}

export default defineComponent({
  components: {},
  props: {
    job: Object,
    applicates: Array,
    select_type: {
      type: String,
      default: "auto"
    }
  },
  setup (props, context) {
    //const router = useRouter()
    const route = useRoute()
    const followerMap = {
      "1000": "1,000人以下",
      "5000": "5,000人以上",
      "10000": "10,000人以上",
      "20000": "20,000人以上",
    }

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"],
      selectedIds: []
    })

    const { applicates } = toRefs(props);
    watch(applicates, () => {
      state.selectedIds = []
    });

    const calcAge = (d:string) => {
      const dateOfBirth = moment(d); 
      const today = moment(); 

      let baseAge = today.year() - dateOfBirth.year(); 
      let birthday = moment(
        new Date(today.year() + "-" + (dateOfBirth.month() + 1) + "-" + dateOfBirth.date())
      );

      if (today.isBefore(birthday)) {
        return baseAge - 1; 
      }
      return baseAge;
    }
    const arraySplit = (s: any) => {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    }
    const addList = (app: any) => {
      var a = state.selectedIds.indexOf(app)
      if(a > -1) {
        state.selectedIds.splice(a, 1)
      } else {
        state.selectedIds.push(app)
      }
      context.emit('syncApps', state.selectedIds)
    }

    return {
      state, calcAge, arraySplit, followerMap, addList
    }
  }
})
