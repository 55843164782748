<template>
  <div class="home">

    <Header></Header>
    <!-- Main -->
    <main class="main container p-0">
      <div class="row">
        <!-- Left field (side bar) -->
        <div class="col-lg-3 left-field">
          <SideBar :currentMenu="'favorites'"></SideBar>
        </div>
        <!-- Right field (content) -->
        <div class="col-lg-9 right-field">
          <section class="tab-content">
          <section class="tab-pane active p-0" id="listLiked" role="tabpanel" aria-labelledby="list-liked">
            <h6>
              <span class="mr-4">いいね! 一覧</span>
              <span class="font--lighter title--small-font">
                御社をお気に入りしたインフルエンサー
              </span>
            </h6>
            <div class="row mx-0">
              <div class="col-md-12 bg--none like-list-wrapper-left">
                <div class="col-md-12 account-information-content-region pb-2">
                  <div class="row title-bar">
                    <div class="col-auto mr-auto">
                      <span><i class="fab fa-instagram icon-font"></i></span>
                      Instagram & Twitter
                    </div>
                  </div>
                  <hr class="line-break" />
                  <div v-if="influencers.length != 0" class="content-section">
                    <ul class="user-list">
                      <li v-for="inf in influencers" :key="inf">
                        <div class="user-list__icon"></div>
                        {{ inf.instagram_id }}
                      </li>
                    </ul>
                  </div>
                  <div class="content-section">
                    <h4 class="text-center pt-5 pb-5 mt-5 mb-5 text-19">お気に入り登録したインフルエンサーはいません</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          </section>
        </div>
      </div>
    </main>

  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios"
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
export default {
  name: "Dashboard",
  data() {
    return {
      idToken: localStorage.getItem("idToken"),
      org: {},
      influencers: [],
    }
  },
  components: {Header, SideBar},
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/favorites", {
        headers: {
          Authorization: `Bearer ${this.idToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((d) => {
        this.influencers = d.data.influencers
      })
  },
}
</script>

<style scoped lang="scss">
    .account-information-content-region {
      padding: 50px 60px;
    }

    @media (max-width: 1199.98px) {
      .account-information-content-region {
        padding: 1.5rem;
      }
    }

    @media (max-width: 991.98px) {
      .account-information-content-region {
        padding: 1rem;
      }
    }

    .title-bar {
      font-size: 1.15rem;
      background-color: white !important;
      line-height: 1;
    }

    .title--small-font {
      font-size: 0.9em;
    }

    .icon-font {
      font-size: 1.5rem;
      position: relative;
      top: 1px;
    }

    .bg--none {
      background: none !important;
    }

    /* Member dropdown button */
    .member-button {
      padding: 0.175rem 0.75rem;
    }

    .content-section>.row {
      margin-bottom: 1rem;
    }

    .user-list {
      list-style: none;
      padding-left: 0;
    }

    .user-list li {
      margin-bottom: 22px;
    }

    .user-list li .user-list__icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: #cacaca;
      color: #cacaca;
      border: none;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 1rem;
    }

    .pagination {
      padding: 30px 12px 14px 12px;
    }

    .pagination ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .pagination a {
      display: inline-block;
      padding: 8px 10px;
      color: rgb(53, 53, 53);
      text-decoration: none;
      font-size: 20px;
      line-height: 1;
    }

    .pagination a.page-next-btn {
      color: rgb(189, 184, 184);
    }

    .pagination .is-active li {
      font-weight: bold;
      border-bottom: 1.5px solid rgb(53, 53, 53);
    }

    .title-align-auto {
      text-align: left;
    }

    .like-list-wrapper-left {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: 15px;
    }

    .like-list-wrapper-right {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-bottom: 15px;
    }

    .line-break {
      margin-bottom: 29px;
    }

    @media (min-width: 767.98px) {
      .title-align-auto {
        text-align: right;
      }

      .like-list-wrapper-left {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }

      .like-list-wrapper-right {
        padding-left: 15px !important;
        padding-right: 0 !important;
      }
    }
</style>
