
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)
    const jobs = computed(() => store.state.job.jobs)

    const freePlans  = ["price_1L01T4GhouLonqYfiv7drttp", "price_1L01RFGhouLonqYfec98SKNk"]

    const toJumpPage = (s: string) => {
      const n = jobs.value.length
      if(s == 'Settings') {
        router.push({ name: s })
      } else if(org.value["plan_id"] == 'price_1JtU67GhouLonqYfmsiPetlT' && n > 0) {
        Swal.fire({
          title: '案件を作成できません。',
          text: "現在のプランだと、これ以上案件を作成できません",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else {
        router.push({ name: s })
      }
    }
    const isAvailableCreate = () => {
      return (freePlans.indexOf(org.value.plan_id) == -1)? true : false
    }

    onMounted(async () => {
      store.dispatch('user/fetchUser')
      store.dispatch('job/fetchJobs', "all")
    })
    return {
      user, org, toJumpPage, jobs, freePlans, isAvailableCreate
    }
  }
})

