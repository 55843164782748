
import { reactive, defineComponent, onMounted, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import config from "@/const/const"

export default defineComponent({
  components: {},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)

    const state = reactive({
      currentTab: "",
    })

    const reload = () => {
      //router.push({ name: "Callback", force: true })
      router.push({ name: "Dashboard" })
    }

    onMounted(async () => {
      store.dispatch('user/fetchUser')
        .catch((err) => {
          if (err.response.status == 301) {
            router.push({ name: "GettingStarted" })
          }
        })

      store.dispatch('user/callbackUser')
        .then((res) => {
          const org = res.data.org

          if(org.state == config.ORG.UNDER_REVIEW) {
            router.push({ name: "UnderReview" })
          }
          if(org.state == config.ORG.REJECTED) {
            router.push({ name: "Rejected" })
          }
          if(org.state == config.ORG.COMPLETE_CANCELLATION) {
            router.push({ name: "CompleteCancellation" })
          }
          if (
            org.state == config.ORG.USE_BILLING_PLAN ||
            org.state == config.ORG.USE_FREE_PLAN ||
            org.state == config.ORG.UNDER_STOP_PLAN
          ) {
            router.push({ name: "Dashboard" })
          }
        })
        .catch((err) => {
          if (err.response.status == 301) {
            router.push({ name: "GettingStarted" })
          }
        })

 

    })

    return {
      user, org, 
      state, auth, reload
    }
  }
})

