import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import authConfig from '../auth_config.json'
import { setupAuth } from './auth'

const app = createApp(App).use(router).use(store)
import '@stripe/stripe-js'
import "@/assets/css/base.css";
import "@/assets/css/normalize.css";

function callbackRedirect (appState: any) {
  router.push(
    appState && appState.targetUrl ? appState.targetUrl : '/callback'
  )
}

setupAuth(authConfig, callbackRedirect).then((auth: any) => {
  app.use(auth).mount('#app')
})

