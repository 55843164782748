
import { defineComponent, reactive } from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment'

interface State {
  state: string;
  job_uuid: string | string[];
}

export default defineComponent({
  components: {},
  props: {
    applicates: Array,
    job: Object
  },
  setup () {
    //const router = useRouter()
    const route = useRoute()
    const followerMap = {
      "1000": "1,000人以下",
      "5000": "5,000人以上",
      "10000": "10,000人以上",
      "20000": "20,000人以上",
    }

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"]
    })
    const calcAge = (d:string) => {
      const dateOfBirth = moment(d); 
      const today = moment(); 

      if(dateOfBirth.isValid() == false) {
        return "-"
      }

      let baseAge = today.year() - dateOfBirth.year(); 
      let birthday = moment(
        new Date(today.year() + "-" + (dateOfBirth.month() + 1) + "-" + dateOfBirth.date())
      );
      console.log(birthday)

      if (today.isBefore(birthday)) {
        return baseAge - 1; 
      }
      return baseAge;
    }

    return {
      state, calcAge, followerMap
    }
  }
})
