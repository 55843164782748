

import { createStore } from "vuex"
import axios from 'axios'

const state ={
  autoSelectInfluencers: [],
  all_applicates: []
}
const actions = {
  fetchAutoSelection({commit}: {commit: any}, payload: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_uuid + "/auto_selection?m=" + payload.m, headers)
      .then((res: any) => {
        commit("setAutoSelectInfluencers", res.data)
      })
  },
  updateApplicate({commit}: {commit: any}, payload: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_id + "/applicates/" + payload.applicate_id, {applicate: {applicate_state: payload.s}} , headers)
  },
  fetchApplicates({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_id + "/applicates", headers)  
      .then((res: any) => {
        commit("setApplicates", res.data.applicates)
      })
  },
  updatePostState({commit}: {commit: any}, payload: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_id + "/applicates/" + payload.applicate_id, {applicate: {post_state: payload.s}} , headers)
  }
}
const mutations = {
  setAutoSelectInfluencers(state: any, influencers: any) {
    state.autoSelectInfluencers = influencers
  },
  setApplicates(state: any, applicates: object) {
    state.all_applicates = applicates
  },
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

