
import { defineComponent, onMounted, reactive, computed } from 'vue'
import Header from "@/components/common/Header.vue"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ConfirmationJob from "@/components/jobs/ConfirmationJob.vue"
import PostSchejule from "@/components/jobs/preview/PostSchejule.vue"

interface State {
  isConfirmationMode: boolean;
  uploadedImage: string;
  img_name: string;
  job_uuid: string | string[];
  isProduct: boolean;
}

export default defineComponent({
  components: {Header, ConfirmationJob, PostSchejule},
  setup () {
    const store = useStore()
    const route = useRoute()

    const state = reactive<State>({
      isConfirmationMode: false,
      uploadedImage: "",
      img_name: "",
      job_uuid: route.params["job_uuid"],
      isProduct: false
    })

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)

    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
        .then(() => {})
    })

    return {
      state, job, product, 
    }
  }
})
