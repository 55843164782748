

import { defineComponent, onMounted, inject, computed } from 'vue'
import { useAuth0, state } from "../../auth/index.js";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
const { logoutAuth, initAuth } = useAuth0(state);
initAuth();

export default defineComponent({
  components: {Header, SideBar},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)

    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })
    const cancel = () => {
      router.push({ name: "Settings" })
    }

    const logout = () => {
      logoutAuth()
    }
    return {
      logout, user, org, cancel, logoutAuth, auth
    }
  }
})

