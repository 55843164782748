
import { defineComponent, onMounted, inject, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
import axios from 'axios'
import moment from 'moment'


interface State {
  invoicesList: any;
  invoice: any;
  lines: any;
  amount: number;
  selectedMonth: number;
}

export default defineComponent({
  components: {Header, SideBar},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)
    const state = reactive<State>({
      invoicesList: [],
      invoice: {},
      lines: [],
      amount: 0,
      selectedMonth: 0
    })
    const planLabel = {
      "price_1L01T4GhouLonqYfiv7drttp": "無料契約プラン",
      "price_1L01RFGhouLonqYfec98SKNk": "倉庫保管プラン",
      "price_1JtU67GhouLonqYfmsiPetlT": "ワンショットプラン",
      "price_1JtU59GhouLonqYfZurbVHiL": "有料プラン/ 3ヶ月更新",
      "price_1JtU59GhouLonqYfEg8AVIcr": "有料プラン/ 6ヶ月更新",
      "price_1JtU59GhouLonqYflsN51Gk0": "有料プラン/ 1年新",
    } 

    onMounted(async () => {
      store.dispatch('user/fetchUser')

      const idToken: string | null = localStorage.getItem("idToken")
      const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/invoices", headers)
        .then((res: any) => {
          const invs = res.data.invoices
          const months = Object.keys(invs)
          var arr:any = []

          for(const i in months) {
            arr.push({label: months[i], value: i})
          }
          state.invoicesList = arr
          state.invoice = months[0]
          state.lines = invs[months[0]]

          for(const i in state.lines) {
            state.amount += state.lines[i].amount || state.lines[i].amount_paid
          }
        })

    })
    const cancel = () => {
      router.push({ name: "Settings" })
    }
    const dateFormat = (d:string) => {
      return moment(d).format("YYYY年MM月")
    }
    const dateFormatDay = (d:number) => {
      return moment.unix(d).format("YYYY/MM/DD")
    }
    const updateInvoice = () => {
      state.amount = 0

      const idToken: string | null = localStorage.getItem("idToken")
      const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/invoices", headers)
        .then((res: any) => {
          const invs = res.data.invoices
          const months = Object.keys(invs)

          state.invoice = months[state.selectedMonth]
          state.lines = invs[months[state.selectedMonth]]

          for(const i in state.lines) {
            state.amount += state.lines[i].amount || state.lines[i].amount_paid
          }
        })

    }
    const onSubmitUpdate = () => {
      store.dispatch('user/updateUser')
        .then(() => {
          store.dispatch('user/updateOrganization')
          router.push({ name: "Settings" })
        })
    }
    const logout = () => {
      auth.logout({
        returnTo: window.location.origin,
      })
    }
    return {
      logout, user, org, onSubmitUpdate, cancel, state, dateFormat,dateFormatDay, planLabel, updateInvoice
    }
  }
})

