import { createStore } from "vuex"
import axios from 'axios'

const state ={
  staffs: []
}
const actions = {
  fetchStaffs({commit}: {commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs", {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
        commit("setStaffs", res.data.staffs)
      })
  },
  submitCreateStaff({commit}: {commit: any}, staff: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    axios
      .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs", {staff: staff} , {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
        commit("addStaff", res.data)
      }).catch((e) => {
        console.log(e)
      })
  },

  submitUpdateStaff({commit}: {commit: any}, staff: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs/" + staff.id, {staff: staff} , {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
        console.log(res)
      }).catch((e) => {
        console.log(e)
      })
  },

  destroyStaff({commit}: {commit: any}, staff_id: number) {
    const idToken: string | null = localStorage.getItem("idToken")
    axios
      .delete(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs/" + staff_id.toString(), {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
      }).catch((e) => {
        console.log(e)
      })
  }

}
const mutations = {
  setStaffs(state: any, staffs: object) {
    state.staffs = staffs
  },
  addStaff(state: any, staff: object) {
    state.staffs.push(staff)
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

