
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
import Swal from "sweetalert2"
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

interface State {
  staff: object;
  isCreateUser: boolean,
}

export default defineComponent({
  components: {Header, SideBar, Form, Field, ErrorMessage},
  setup () {
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)
    const staffs = computed(() => store.state.staff.staffs)
    const state = reactive<State>({
      staff: {},
      isCreateUser: false
    })
    const cancel = () => {
      router.push({ name: "Settings" })
    }
    const schema = yup.object({
      last_name: yup.string().required("名前は必須です"),
      first_name: yup.string().required("名前は必須です"),
      email: yup.string().required("メールアドレスは必須です"),
    });

    const updateRole = (staff: any) => {
      Swal.fire({
        title: '権限を変更しますか？ ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '変更'
      }).then((result) => {
        if (result.isConfirmed) {

          store.dispatch('staff/submitUpdateStaff', staff)
          Swal.fire(
            '権限を変更しました',
            '正常に変更されました',
            'success'
          )
        }
      })


    }
    onMounted(async () => {
      store.dispatch('user/fetchUser')
      store.dispatch('staff/fetchStaffs')
    })
    const switchMemberForm = () => {
      state.isCreateUser = !state.isCreateUser
    }
    const onSubmitNewStaff = () => {
      store.dispatch('staff/submitCreateStaff', state.staff)
        .then((d) => {
          console.log(d)
          state.isCreateUser = false
        })
    }
    const onDestroyUser = (s:any) => {
      Swal.fire({
        title: '削除しますか？ ',
        text: "ユーザーを削除すると元に戻せません",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '削除'
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch("staff/destroyStaff", s.id)
          Swal.fire(
            '削除しました',
            'ユーザーを正常に削除しました',
            'success'
          )
        }
      })


    }
    return {
      state, user, org, staffs, onSubmitNewStaff, cancel, onDestroyUser, switchMemberForm,
      schema, updateRole
    }
  }
})
