
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    job: Object,
    olps: Array
  },
  setup (props) {
    onMounted(async () => {
      console.log("---")
      console.log(props.job)
    })
    const getAlreadyNum = () => {
      var n = 0
      if(props.olps == undefined) {
        return 0
      }
      props.olps.forEach((o:any) => {
        n += parseInt(o['stock'])
      })
      return n
    }
    const getMonitorNum = () => {
      if(props.job == null) {
        return 0
      } else {
        if(props.job.monitor_num == null) {
          return 0
        } else {
          return props.job.monitor_num
        }
      }
    }
    return {getMonitorNum, getAlreadyNum}
  }
})
