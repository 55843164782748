
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()

    const org = computed(() => store.state.user.organization)

    onMounted(async () => {
      store.dispatch('user/fetchUser')
        .then(() => {
          if(org.value.state == "use_billing_plan" || org.value.state == "use_free_plan" || org.value.state == "under_stop_plan") {
            router.push({ name: "Dashboard" })
          }
        })
    })
  }
})
