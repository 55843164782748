
import { defineComponent, onMounted, computed } from 'vue'
import PostSchejule from "@/components/jobs/preview/PostSchejule.vue"
import InfluencerEstimate from "@/components/jobs/preview/InfluencerEstimate.vue"
import moment from "moment"
import { useStore } from 'vuex'

export default defineComponent({
  components: {PostSchejule, InfluencerEstimate},
  props: {
    job: Object,
    product: Object,
    mode: {
      type: String,
      default: "confirm"
    },
    olps: Array
  },
  setup(props) {
    const store = useStore()
    const booleanLabel = {
      "true": "あり",
      "false": "なし"
    }
    const org = computed(() => store.state.user.organization)

    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })

    const dateFormat = (d:string) => {
      return moment(d).format("Y/M/D")
    }
    const isIncludeMedia = (s:string) => {
      var job = props.job
      if(job != undefined) {
        return (job.media.indexOf(s) > -1)? true : false
      }
    }
    const checkBoxWrapperClass = (s:string) => {
      var job = props.job
      if(job != undefined) {
        return (job.media.indexOf(s) > -1)? "" : "uncheck-box-wrapper"
      }
    }

    const arraySplit = (s: any) => {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    }

    return {
      isIncludeMedia, booleanLabel, checkBoxWrapperClass, arraySplit, dateFormat, org
    }

  }
})
