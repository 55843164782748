
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import config from "@/const/const"
import Swal from 'sweetalert2'
import Header from "@/components/common/Header.vue"
import moment from "moment"

interface State {
  job_uuid: string | string[];
  freeword: string;
}

export default defineComponent({
  components: {Header},
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const warehouses = computed(() => store.state.warehouse.warehouses)

    const state = reactive<State>({
      job_uuid: route.params["job_uuid"],
      freeword: ""
    })
    const getImage = (j: any) => {
      if(j.images == undefined) {
        return require("@/assets/product.png")
      } else if(j.images == "") {
        return require("@/assets/product.png")
      } else {
        return j.images.split(',')[0]
      }
    }
    const freewordSearch = () => {
      store.dispatch('warehouse/fetchWarehouse', {word: state.freeword})
        .then((r) => {
          console.log(r)
        })
    }
    const jobStateLink = (j: any) => {
      if (j.publish_state == config.JOB.UNDER_REVIEW) {
        return "jobs/" + j.uuid + "/under_review_job"
      } else if (j.publish_state == config.JOB.DRAFT) {
        return "jobs/" + j.uuid + "/form"
      } else if (j.publish_state == config.JOB.PASSED) {
        return "jobs/" + j.uuid + "/passed_job"
      } else if (j.publish_state == config.JOB.REJECTED) {
        return "jobs/" + j.uuid + "/form"
      } else if (j.publish_state == config.JOB.SELECTED) {
        return "jobs/" + j.uuid + "/post_confirm"
      } else if (j.publish_state == config.JOB.PUBLISHED) {
        var n = moment()
        var application_s_at = moment(j.application_start_datetime)
        var application_e_at = moment(j.application_end_datetime)
        if (n.isBetween(application_s_at, application_e_at)) {
          return "jobs/" + j.uuid + "/published_job"
        } else if (n.isAfter(application_e_at)) {
          return "jobs/" + j.uuid + "/select"
        } else if (n.isBefore(application_s_at)) {
          return "jobs/" + j.uuid + "/published_job"
        }
      } else if (j.publish_state == config.JOB.COMPLETED) {
        return "jobs/" + j.uuid + "/post_confirm"
      }
    }


    const isSame = (w: any) => {
      var r = false
      var c = 0

      warehouses.value.forEach((wh: any) => {
        if(w.job.uuid == wh.job.uuid) {
          c += 1
        }
        if(w.product.id == wh.product.id && c == 1) {
          r = true
        }
      })
      return r
    }

    const countSameJob = (w: any) => {
      var c = 0
      warehouses.value.forEach((wh: any) => {
        if(w.job.uuid == wh.job.uuid) {
          c += 1
        }
      })
      return c
    }

    const duplicateJob = (w: any) => {
      Swal.fire({
        title: 'この商品を利用して案件を作成しますか？',
        text: "作成すると、案件編集画面へと遷移します",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ea0e1',
        cancelButtonColor: '#ed3773',
        confirmButtonText: '商品を利用'
      }).then((result) => {
        if(result.isConfirmed) {
          store.dispatch('job/duplicateJob', {job_id: w.job.uuid})
            .then((res:any) => {
              //return "jobs/" + j.uuid + "/form"
              //router.push({ name: s })
              console.log(res)
            })
        }
      })


    }


    const returnProduct = (w: any) => {

      Swal.fire({
        title: '返送を希望しますか？',
        text: "希望すると、御社に荷物が返送されます。配送料は返金されません",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ea0e1',
        cancelButtonColor: '#ed3773',
        confirmButtonText: '返送希望'
      }).then((result) => {
        if(result.isConfirmed) {
          var ty = "product"
          if(w.size != "") {
            ty = "olp"
          }
          store.dispatch('warehouse/returnWarehouse', {w_id: w.id, w_type: ty})
            .then(() => {
              store.dispatch('warehouse/fetchWarehouse')
            })
        }
      })

    }

    onMounted(async () => {
      store.dispatch('warehouse/fetchWarehouse', {word: ""})
    })
    return {
      warehouses, router, state, returnProduct, getImage, duplicateJob, countSameJob, isSame, jobStateLink, freewordSearch
    }
  }
})
