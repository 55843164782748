
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"

interface State {
  state: string;
  job_uuid: string | string[];
  mode: string;
  size_stock: any;
}

export default defineComponent({
  components: {Header},
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)
    const applicates = computed(() => store.state.job.applicates)

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"],
      mode: "",
      size_stock: {}
    })

    const isAlreadySelected = (s:string, c:string) => {
      const n = state.size_stock[c][s]
      var count = 0

      for(const inf of applicates.value) {
        if(inf.size == s && inf.color == c && inf.applicate_state == "applied") {
          count += 1
        }
      }
      return n == count
    }

    const isSelectFinish = () => {
      //var max_c = 0
      //var c = 0
      //for (const [color, size_s] of Object.entries(state.size_stock)) {
      //  for(const [s, n] of Object.entries(size_s)) {
      //    max_c += 1
      //    console.log(n)
      //    if(isAlreadySelected(s, color)) {
      //      c += 1
      //    }
      //  }
      //}
      //if(c == max_c) {
      //  store.dispatch('job/updateJob', {uuid: state.job_uuid, publish_state: "selected"})
      //    .then(() => {
      //      router.push({name: "DefinitionInfluencer", params: { job_uuid: state.job_uuid }})
      //    })
      //}
    }

    onMounted(async () => {
      state.mode = String(route.query["mode"])

      store
        .dispatch('job/fetchJob', state.job_uuid)
        .then(() => {
          if(job.value["is_size"] == true || job.value["is_variation"] == true) {
            state.size_stock  = JSON.parse(product.value["variation_color_stock_json"])

            isSelectFinish()
          }
        })
    })
    return {
      job, product, router, state, isAlreadySelected
    }
  }
})
