
import ConfirmationJob from "@/components/jobs/ConfirmationJob.vue"
import InstagramFeedDirection from "@/components/jobs/form/InstagramFeedDirection.vue"
import TwitterFeedDirection from "@/components/jobs/form/TwitterFeedDirection.vue"
import InstagramStoryDirection from "@/components/jobs/form/InstagramStoryDirection.vue"
import EstimateConfirmation from "@/components/jobs/form/EstimateConfirmation.vue"
import PreviewJob from "@/components/jobs/PreviewJob.vue"
import ProgressBar from "@/components/jobs/ProgressBar.vue"
import { defineComponent, onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import Header from "@/components/common/Header.vue"
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import "@/assets/css/job.css";
import moment from 'moment'

interface State {
  isConfirmationMode: boolean;
  job: any;
  product: any;
  uploadedImage: string;
  img_name: string;
  medias: string[];
  gender: string;
  experience: string;
  category: string;
  isPamphlet: boolean;
  min_age: number;
  max_age: number;
  sizeList: string[];
  colorList: string[];
  sizeColorStock: any;
}
import Swal from 'sweetalert2'

export default defineComponent({
  components: { ConfirmationJob, Header, PreviewJob, ProgressBar, InstagramFeedDirection, InstagramStoryDirection, TwitterFeedDirection, EstimateConfirmation, Field, Form, ErrorMessage},
  setup () {
    const store = useStore()
    const router = useRouter()

    const schema = yup.object({
      title: yup.string().required("案件名は必須です").max(40, '40文字以内で入力してください').min(2, "２文字以上で入力してください"),
      service: yup.string().required("商品・サービスは必須です").nullable(true),
      product_title: yup.string().required("商品の品名は必須です。").max(20).nullable(true),
      category: yup.string().required("カテゴリーを選択してください"),
      product_description: yup.string().required("商品の説明は必須です").max(500).nullable(true),
      monitor_num: yup.number().required("起用する人数は必須です").min(1, "1人以上入力してください").max(100, '100人以内までで指定してください').nullable(true),
      follower: yup.number().required("最低フォロワー数は必須です").nullable(true),
      medias: yup.array().min(1, "媒体は１つ以上選択してください").required("媒体は１つ以上選択してください"),
      max_age: yup.number().max(80, '80歳以下').required('年齢は必須です'),
      min_age: yup.number().min(10, '10歳以上').required('年齢は必須です')
    });

    const state = reactive<State>({
      isConfirmationMode: false,
      product: {},
      job: {},
      uploadedImage: "",
      img_name: "",
      medias: [],
      gender: "",
      experience: "",
      category: "",
      isPamphlet: false,
      min_age: 20,
      max_age: 30,
      sizeList: ["Sサイズ", "Mサイズ", "Lサイズ"],
      colorList: ["ホワイト", "ブラック"],
      sizeColorStock: {"ホワイト": {"Sサイズ": 0, "Mサイズ": 0, "Lサイズ": 0}, "ブラック": {"Sサイズ": 0, "Mサイズ": 0, "Lサイズ": 0}},
    })
    const categories = [
      "アウトドア", "インテリア", "キッチン", "コスメ", "家具家電", "洋服", "おもちゃ", "ハンドメイド", "フード", "こども服", "エンタメ", "スポーツ", "アクセサリー"
    ]

    const selectNewLists = [
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
    ]
    const hashtagLists = [
      {label: "#pr", type: "input"},
      {label: "#ouvo", type: "input"},
      {label: "", type: "new"},
    ]
    const instagramOptionSelection = [
      {label: "枚以上投稿してください", type: "topInput", prefix: ""},
      {label: "弊社アカウントをメンションしてください", type: "sInput", prefix: "@"},
      {label: "ロゴ・商品パッケージを必ず入れてください", type: "normal", prefix: ""},
      {label: "実際に使用しているシーンを撮影ください", type: "normal", prefix: ""},
      {label: "顔出し必須でお願いします", type: "normal", prefix: ""},
      {label: "顔出しは必須ではありません", type: "normal", prefix: ""},
      {label: "商品の色味がわからなくなる画像補正はしないでください", type: "normal", prefix: ""},
      {label: "使用感を訴求してください", type: "normal", prefix: ""},
      {label: "高級を訴求してください", type: "normal", prefix: ""},
      {label: "ナチュラル感を訴求してください", type: "normal", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
    ]
    const org = computed(() => store.state.user.organization)
    const jobs = computed(() => store.state.job.jobs)
   
    const switchConfirmation = (values:any) => {
      const n = state.job.monitor_num
      const l = state.job.images.length
      console.log(values)

      const a_s = moment(state.job.application_start_datetime)
      const a_e = moment(state.job.application_end_datetime)
      const p_s = moment(state.job.start_post_at)
      const p_e = moment(state.job.end_post_at)

      if(a_s > a_e) {
        Swal.fire({ title: '入力エラー', text: "応募期間の整合性があっていません", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
      } else  if(p_s > p_e) {
        Swal.fire({ title: '入力エラー', text: "応募期間の整合性があっていません", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
      } else if(l == 0) {
        Swal.fire({
          title: '入力エラー',
          text: "写真は一枚以上選択してください",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else if(l > 6) {
        Swal.fire({
          title: '入力エラー',
          text: "写真は6枚以下選択してください",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else {
        if(org.value["plan_id"] == 'price_1JtU67GhouLonqYfmsiPetlT' && n > 10) {
          Swal.fire({
            title: '入力エラー',
            text: "現在のプランだと、10人までしか指定できません",
            icon: 'error',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        } else {
          state.job.category = state.category
          state.job.gender = state.gender
          state.job.experience = state.experience
          state.job.media = state.medias
          state.job.min_age = state.min_age
          state.job.max_age = state.max_age
          state.job.description = state.product.description
          state.job.is_pamphlet = state.isPamphlet
          state.product.color_list = state.colorList.join(",")
          state.product.size_list = state.sizeList.join(",")
          state.product.variation_color_stock_json = JSON.stringify(state.sizeColorStock)

          window.scrollTo({ top: 0, behavior: "smooth"});
          state.isConfirmationMode = !state.isConfirmationMode         
        }
      }

    }
    const onChangeArray = (ar:string[], v:string) => {
      if(ar.indexOf(v) == -1) {
        ar.push(v)
      } else {
        ar.splice(ar.indexOf(v), 1)
      }
    }
    const onChangeSelect = (s: string, v:string) => {
      if(s == 'gender') {
        state.gender = v
      } else if(s == 'category') {
        state.category = v
      } else if(s == 'experience') {
        state.experience = v
        onChangeArray(state.medias, 'experience')
      }
    }
    const updateLabel = (type:string, index:number, label:string) => {
      var result:any = {}
      if(type == "color") {
        var o = state.sizeColorStock[label]
        state.colorList.forEach((s, i) => {
          result[s] = (i == index)? o : state.sizeColorStock[s]
        })
      }
      if(type == "size") {
        state.colorList.forEach((c) => {
          result[c] = {}
          state.sizeList.forEach((s, i) => {
            if(i == index) {
              result[c][s] = state.sizeColorStock[c][label]
            } else {
              result[c][s] = state.sizeColorStock[c][s]
            }
          })
        })
      }
      state.sizeColorStock = result
    }
    const updateSumMonitor = (t: any) => {
      if(isNaN(t) == false) {
        state.job.monitor_num = 0
        for(var color in state.sizeColorStock) {
          for(var size in state.sizeColorStock[color]) {
            state.job.monitor_num += parseInt(state.sizeColorStock[color][size])
          }
        }
      } else {
        Swal.fire({
          title: '入力エラー',
          text: "半角数字で入力してください。",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })

      }
      
    }
    const removeImage = (i:number) => {
      var arr:string[] = []
      for(var im in state.job.images) {
        if(parseInt(im) != i) {
          arr.push(state.job.images[im])
        }
      }

      state.job.images = arr
    }
    const onSubmitAsDraft = () => {
      state.job.publish_state = "draft"
      //state.job.profile = state.uploadedImage
      state.job.category = state.category
      state.job.gender = state.gender
      state.job.experience = state.experience
      state.job.media = state.medias.join(",")
      state.job.min_age = state.min_age
      state.job.max_age = state.max_age
      state.job.description = state.product.description
      state.job.is_pamphlet = state.isPamphlet
      state.product.color_list = state.colorList.join(",")
      state.product.size_list = state.sizeList.join(",")
      state.product.variation_color_stock_json = JSON.stringify(state.sizeColorStock)

      if(state.job.title == "" || state.job.title == null || state.job.title == undefined) {
        Swal.fire({
          title: '入力エラー',
          text: "タイトルは入力してください。",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })

        return;
      }

      Swal.fire({
        icon: 'info',
        title: '保存中'
      });
      Swal.showLoading();

      store.dispatch("job/createJob", state.job)
        .then((d:any) => {
          state.product.job_id = d.data.uuid

          store.dispatch("product/createProduct", state.product)
            .then(() => {
              Swal.close()
              Swal.fire({
                title: '下書き保存しました',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
              }).then((result) => {
                if(result.isConfirmed) {
                  router.push({name: "Dashboard", params: {}})
                }
              })
            })
        })
    }

    const onChangeDate = (d1:Date, d2:Date) => {
      if(d1 > d2) {
        Swal.fire({ title: '入力エラー', text: "日時の整合性があいません", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
      }
    }
    const onFileChange = (e:any) => {
      const files = e.target.files || e.dataTransfer.files;
      if(state.job.images == null) {
        state.job.images = []
      }
      if(files.length > 6 || (files.length + state.job.images.length) > 6) {
        Swal.fire({ title: '入力エラー', text: "画像は6枚までにしてください", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
        return;
      }
      const sizeLimit = 1024 * 1024 * 10;

      for(const f in files) {
        const reader = new FileReader();
        reader.onload = (e:any) => {
          state.job.images.push(e.target.result);
        };

        if (files[f].size > sizeLimit) {
          Swal.fire({ title: '入力エラー', text: "ファイルサイズは10MB以下にしてください", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
          return; 
        } else {
          if(files[f].size != undefined) {
            reader.readAsDataURL(files[f]);
          }
        }
      }
    }
    const onSubmitJob = () => {
      state.job.publish_state = "under_review"
      state.job.media = state.medias.join(",")
      state.job.description = state.product.description
      //state.job.profile = state.uploadedImage

      Swal.fire({
        icon: 'info',
        title: '投稿中'
      });
      Swal.showLoading();

      store.dispatch("job/createJob", state.job)
        .then((d: any) => {
          state.product.job_id = d.data.uuid
          state.product.color_list = state.colorList.join(",")
          state.product.size_list = state.sizeList.join(",")
          state.product.variation_color_stock_json = JSON.stringify(state.sizeColorStock)

          store.dispatch("product/createProduct", state.product)
            .then(() => {
              Swal.close()
              window.scrollTo({ top: 0, behavior: "smooth"});
              router.push({name: "UnderReviewJob", params: { job_uuid: state.product.job_id }})
            })
        })
    }
    const isIncludeMedia = (s:string) => {
      return (state.medias.indexOf(s) > -1)? true : false
    }
    const isActive = (ar:string[], s:string) => {
      return (ar.indexOf(s) > -1)? "active-check" : "" 
    }
    const isActiveSelect = (s: string, v:string) => {
      if(s == 'gender') {
        return (state.gender == v)? "active-check" : "" 
      } else if(s == 'category') {
        return (state.category == v)? "active-check" : "" 
      } else if(s == 'experience') {
        return (state.experience == v)? "active-check" : "" 
      }
    }

    const updateInstagramImageOpt = (opt:string[]) => {
      state.job.instagram_caution_image = opt.join(",") 
    }
    const updateInstagramCaptionOpt = (opt:string[]) => {
      state.job.instagram_caution_caption = opt.join(",") 
    }
    const updateInstagramReccomendHashtag = (opt:string[]) => {
      state.job.recommend_hashtags = opt.join(",") 
    }
    const updateInstagramFeedMustHashtag = (opt:string[]) => {
      state.job.must_hashtags = opt.join(",") 
    }
    const updateInstagramStoryOpt = (opt:string[]) => {
      state.job.instagram_cation_story = opt.join(",") 
    }
    const updateInstagramMustHashtag = (opt:string[]) => {
      state.job.must_story_hashtags = opt.join(",") 
    }
    const updateInstagramReccomendStoryHashtag = (opt:string[]) => {
      state.job.recommend_story_hashtags = opt.join(",") 
    }
    const updatePostNum = (n: number) => {
      state.job.instagram_post_num = n
    }
    const updateTwitterImageOpt = (opt:string[]) => {
      state.job.twitter_caution_image = opt.join(",") 
    }
    const updateTwitterCaptionOpt = (opt:string[]) => {
      state.job.twitter_caution_text = opt.join(",") 
    }
    const updateTwitterMustHashtag = (opt:string[]) => {
      state.job.twitter_must_hashtags = opt.join(",") 
    }
    const updateTwitterReccomendHashtag = (opt:string[]) => {
      state.job.twitter_recommend_hashtags = opt.join(",") 
    }
    const updateStoreOpt = (opt:string[]) => {
      state.job.experience_caution = opt.join(",") 
    }
    const updateStoreInfo = (visit_s_at:string, visit_e_at:string, visit_name: string, visit_address:string) => {
      state.job.experience_place_name = visit_name
      state.job.experience_visted_start_at = visit_s_at
      state.job.experience_visted_end_at = visit_e_at
      state.job.experience_place = visit_address
    }

    const setSize = () => {
      if(state.job.is_size == 'false') {
        state.sizeList = ["フリーサイズ"]
        initialSizeColor()
      }
    }
    const setVariation = () => {
      if(state.job.is_variation == 'false') {
        state.colorList = ["ワンカラー"]
        initialSizeColor()
      }
    }

    const openCaution = () => {
      Swal.fire({
        title: '商品発送のご注意事項',
        icon: 'info',
        text: "---------------------------- ",
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
    }

    const addVariation = () => {
      state.colorList.push("カラー" + (state.colorList.length + 1).toString())
      initialSizeColor()
    }
    const deleteVariation = (t:string) => {
      var r:string[] = []
      for(var i in state.colorList) {
        if(state.colorList[i] != t) {
          r.push(state.colorList[i])
        }
      }
      state.colorList = r
      initialSizeColor()
    }
    const deleteSize = (t:string) => {
      var r:string[] = []
      for(var i in state.sizeList) {
        if(state.sizeList[i] != t) {
          r.push(state.sizeList[i])
        }
      }
      state.sizeList = r
      initialSizeColor()
    }
    const addSize = () => {
      state.sizeList.push("サイズ" + (state.sizeList.length + 1).toString())
      initialSizeColor()
      initialSizeColor()
    }

    const initialSizeColor = () => {
      var r:any = {}
      state.colorList.forEach((c) => {
        if(r[c] == undefined) {
          r[c] = {}
        } 
      })
      state.colorList.forEach((c) => {
        state.sizeList.forEach((s) => {
          if(state.sizeColorStock[c] != undefined) {
            r[c][s] = state.sizeColorStock[c][s]
          } else if(r[c][s] == undefined) {
            r[c][s] = 0
          }
        })
      })
      state.sizeColorStock = r

      state.colorList.forEach((c) => {
        state.sizeList.forEach((s) => {
          if(state.sizeColorStock[c][s] == null) {
            state.sizeColorStock[c][s] = 0
          }
        })
      })
      state.sizeColorStock = Object.assign({}, state.sizeColorStock, state.sizeColorStock)
    }
    onBeforeRouteLeave((to, from, next) => {
      Swal.fire({
        title: "未保存のデータがあります",
        text: `ページを離れますか？`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6', 
        cancelButtonColor: '#d33',
        confirmButtonText: "はい",
        cancelButtonText: 'キャンセル',
        reverseButtons: true,
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        }
      });
    });


    onMounted(async () => {
      store.dispatch('user/fetchUser')
      store.dispatch('job/fetchJobs', "all")

      const m = moment()
      state.job.monitor_num = 2
      state.job.application_start_datetime = m.format("YYYY-MM-DD")
      state.job.application_end_datetime = m.add(7, "days").format("YYYY-MM-DD")
      state.job.selection_datetime = m.add(3, 'days').format("YYYY-MM-DD")
      state.job.start_post_at = m.add(1, 'days').format("YYYY-MM-DD")
      state.job.end_post_at = m.add(7, 'days').format("YYYY-MM-DD")
      state.job.instagram_post_num = 0
      state.job.twitter_post_num = 0
      state.job.images = []

      state.job.instagram_caution_image = ""
      state.job.instagram_caution_caption = ""
      state.job.must_hashtags = ""
      state.job.recommend_hashtags = ""

      state.job.instagram_cation_story = ""
      state.job.must_story_hashtags = ""
      state.job.recommend_story_hashtags = ""


      window.onbeforeunload = function() {
      };
      window.addEventListener('popstate', () => {
      });
    })

    return {
      onSubmitJob, onSubmitAsDraft, switchConfirmation, state, 
      categories, instagramOptionSelection, hashtagLists, selectNewLists, onChangeArray, isIncludeMedia, isActive,
      onChangeSelect, isActiveSelect, updateInstagramImageOpt, updateInstagramCaptionOpt, updateInstagramReccomendHashtag,
      updateInstagramStoryOpt, updateInstagramMustHashtag, updateInstagramReccomendStoryHashtag,
      updateTwitterImageOpt, updateTwitterCaptionOpt, updateTwitterMustHashtag, updateTwitterReccomendHashtag,
      schema, updateStoreInfo, updateStoreOpt , onFileChange, updateInstagramFeedMustHashtag,
      updatePostNum, addVariation, addSize, initialSizeColor, jobs, org,
      setVariation, setSize, updateSumMonitor, updateLabel, removeImage,
      deleteSize, deleteVariation, onChangeDate, openCaution
    }
  }
})
