
import { defineComponent } from 'vue'
import Footer from "@/components/common/Footer.vue"

export default defineComponent({
  props: {},
  components: {Footer},
  setup() {

    return {}
  }
})
