import { createStore } from "vuex"
import user from "@/store/modules/user"
import product from "@/store/modules/product"
import staff from "@/store/modules/staff"
import job from "@/store/modules/job"
import applicate from "@/store/modules/applicate"
import card_token from "@/store/modules/card_token"
import warehouse from "@/store/modules/warehouse"
import notification from "@/store/modules/notification"

export default createStore({
  modules: {
    user, staff, job, card_token, product, applicate, warehouse, notification
  }
})


