
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    job: Object,
    product: Object,
  },
  setup () {
    onMounted(async () => {})
  }
})
