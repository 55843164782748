
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import ConfirmationJob from "@/components/jobs/ConfirmationJob.vue"
import ProgressBar from "@/components/jobs/ProgressBar.vue"
import SelectInfluencerTable from "@/components/jobs/SelectInfluencerTable.vue"

interface State {
  state: string;
  job_uuid: string | string[];
}

export default defineComponent({
  components: {Header, ConfirmationJob, ProgressBar, SelectInfluencerTable},
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)
    const applicates = computed(() => store.state.job.applicates)

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"]
    })
    const arraySplit = (s: any) => {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    }
    const jumpAutoSelectPage = (s: string) => {
      window.scrollTo({ top: 0, behavior: "smooth"});
      router.push({name: "AutoSelect", params: { job_uuid: state.job_uuid }, query: {m:s}})
    }
    const jumpManualSelectPage = () => {
      window.scrollTo({ top: 0, behavior: "smooth"});
      router.push({name: "ManualSelectList", params: { job_uuid: state.job_uuid }})
    }

    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
    })
    return {
      job, applicates, product, arraySplit, jumpAutoSelectPage, jumpManualSelectPage
    }
  }
})
