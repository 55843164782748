
import { createStore } from "vuex"
import axios from 'axios'


const state ={
  notifications: [],
}
const actions = {
  fetchNotifications({commit}: {commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/notifications", headers)
      .then((res: any) => {
        commit("setNotifications", res.data.notifications)
      }).catch((err) => {
        if (err.response.status == 301) {
          //this.$router.push({ name: "GettingStarted" })
          console.log("----")
          console.log("301")
        }
      })
  },
}
const mutations = {
  setNotifications(state: any, notifications: any) {
    state.notifications = notifications
  },

}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

