


import { defineComponent, onMounted, inject, computed, reactive, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useStripe } from 'vue-use-stripe'
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"
import axios from 'axios'
import Swal from 'sweetalert2'

export default defineComponent({
  components: {Header, SideBar},
  setup () {
    const event = ref({})

    const auth = inject<any>('$auth')
    const store = useStore()
    const state = reactive({
      selectedCard: "",
      selectedPlan: ""
    })
    const planLabel = {
      "price_1L01T4GhouLonqYfiv7drttp": "無料契約プラン",
      "price_1L01RFGhouLonqYfec98SKNk": "倉庫保管プラン",
      "price_1JtU67GhouLonqYfmsiPetlT": "ワンショットプラン",
      "price_1JtU59GhouLonqYfZurbVHiL": "有料プラン/ 3ヶ月更新",
      "price_1JtU59GhouLonqYfEg8AVIcr": "有料プラン/ 6ヶ月更新",
      "price_1JtU59GhouLonqYflsN51Gk0": "有料プラン/ 1年新",
    }
    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)
    const staffs = computed(() => store.state.staff.staffs)
    const card_tokens = computed(() => store.state.card_token.card_tokens)

    const { stripe, elements: [cardElement]} = useStripe({
      key: "pk_test_51JtTLgGhouLonqYfDFFrDIJ8510zzscnLjg091HQQDl0y0dGyOe397lCAkn2Roh7X5Tld6o9j8g9iDojvA3dYdJ400hdU1wUqh" || '',
      elements: [{ type: 'card', options: {} }],
    })

    const logout = () => {
      auth.logout({
        returnTo: window.location.origin,
      })
    }

    watchEffect(() => {
      state.selectedPlan = org.value.plan_id
      state.selectedCard = org.value.card_token_id
    })
    onMounted(async () => {
      store.dispatch('user/fetchUser')
      store.dispatch('staff/fetchStaffs')
      store.dispatch('card_token/fetchCardTokens')

      console.log("----")
      console.log(user)

    })

    const registerCard = () => {
      stripe.value?.createToken(cardElement.value).then((res:any) => {
        store.dispatch('card_token/createCardToken', {card_token: res.token.id, tokens: res.token.card.id, last4: res.token.card.last4, brand: res.token.card.brand})
          .then((res) => {
            console.log(res)
          })
      })
    }
    const updateCardToken = (id:number) => {
      store.dispatch('card_token/updateCardToken', id.toString())
        .then((res) => {
          console.log(res)
        })
    }
    const deleteCardToken = (id:number) => {
      store.dispatch('card_token/deleteCardToken', id.toString())
        .then((res) => {
          console.log(res)
        })
    }
    const onSubmitWithdrawl = () => {}
    const changePassword = () => {


      Swal.fire({
        title: 'パスワードを変更しますか？',
        text: "パスワードを変更するメールを送られます",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ea0e1',
        cancelButtonColor: '#ed3773',
        confirmButtonText: '変更する',
        cancelButtonText: 'キャンセル'
      }).then((result) => {
        if(result.isConfirmed) {
          const params = {
            client_id: "hR1WowmpXOibvwqXEIr58L1fcpK6A0D5",
            email: user.value.email,
            connection: "Username-Password-Authentication",
          }
          axios
            .post(`https://ouvo.jp.auth0.com/dbconnections/change_password`, params, {headers: { "Content-Type": "application/json"}})
            .then((): void => {
            })
        }
      })

    }
    const applyPlan = () => {
      const idToken: string | null = localStorage.getItem("idToken")
      const params = {
        plan_id: state.selectedPlan,
        card_token_id: state.selectedCard
      }
      axios
        .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/subscriptions/1", params, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
        .then((res:any)  => {
          console.log(res)
        })
    }

    return {
      user, 
      org, 
      staffs, 
      logout, 
      changePassword, 
      onSubmitWithdrawl,
      card_tokens,
      event,
      cardElement,
      registerCard,
      deleteCardToken,
      updateCardToken,
      state, applyPlan,
      planLabel
    }
  }
})

