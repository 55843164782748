
import ConfirmationJob from "@/components/jobs/ConfirmationJob.vue"
import InstagramFeedDirection from "@/components/jobs/form/InstagramFeedDirection.vue"
import TwitterFeedDirection from "@/components/jobs/form/TwitterFeedDirection.vue"
import InstagramStoryDirection from "@/components/jobs/form/InstagramStoryDirection.vue"
import EstimateConfirmation from "@/components/jobs/form/EstimateConfirmation.vue"
import PreviewJob from "@/components/jobs/PreviewJob.vue"
import ProgressBar from "@/components/jobs/ProgressBar.vue"
import { defineComponent, onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate';
import Header from "@/components/common/Header.vue"
import * as yup from 'yup';
import moment from 'moment'
import { useRouter, useRoute  } from 'vue-router'
import Swal from 'sweetalert2'

interface State {
  isConfirmationMode: boolean;
  job: any;
  product: any;
  medias: string[];
  experience: string;
  uploadedImage: string;
  img_name: string;
  job_uuid: string | string[];
  isProduct: boolean;
  category: string;
  gender: string;
  min_age: number;
  max_age: number;
  sizeList: string[];
  colorList: string[];
  sizeColorStock: any;
  isPamphlet: boolean;
}

export default defineComponent({
  components: { ConfirmationJob, Field, Form, ErrorMessage, InstagramFeedDirection, TwitterFeedDirection, InstagramStoryDirection, EstimateConfirmation, PreviewJob, ProgressBar, Header },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    //onBeforeRouteLeave((to, from, next) => {
    //  Swal.fire({
    //    title: "未保存のデータがあります",
    //    text: `ページを離れますか？`,
    //    icon: "warning",
    //    showCancelButton: true,
    //    confirmButtonColor: '#3085d6', 
    //    cancelButtonColor: '#d33',
    //    confirmButtonText: "はい",
    //    cancelButtonText: 'キャンセル',
    //    reverseButtons: true,
    //    heightAuto: false,
    //  }).then((result) => {
    //    if (result.isConfirmed) {
    //      next();
    //    }
    //  });
    //});

    const schema = yup.object({
      title: yup.string().required("案件名は必須です").max(40, '40文字以内で入力してください').min(2, "２文字以上で入力してください"),
      service: yup.string().required("商品・サービスは必須です").nullable(true),
      product_title: yup.string().required("商品の品名は必須です。").max(20).nullable(true),
      category: yup.string().required("カテゴリーを選択してください"),
      product_description: yup.string().required("商品の説明は必須です").max(500).nullable(true),
      monitor_num: yup.number().required("起用する人数は必須です").min(1, "1人以上入力してください").max(100, '100人以内までで指定してください').nullable(true),
      follower: yup.number().required("最低フォロワー数は必須です").nullable(true),
      medias: yup.array().min(1, "媒体は１つ以上選択してください").required("媒体は１つ以上選択してください"),
      max_age: yup.number().max(80, '80歳以下').required('年齢は必須です'),
      min_age: yup.number().min(10, '10歳以上').required('年齢は必須です')
    });


    const state = reactive<State>({
      isConfirmationMode: false,
      uploadedImage: "",
      img_name: "",
      job_uuid: route.params["job_uuid"],
      job: {},
      experience: "",
      product: {},
      isProduct: false,
      medias: [],
      category: "",
      gender: "",
      min_age: 0,
      max_age: 0,
      sizeList: [],
      colorList: [],
      sizeColorStock: {},
      isPamphlet: false,
    })

    const org = computed(() => store.state.user.organization)
    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)
    const olps = computed(() => store.state.job.olps)
    const freePlans  = ["price_1L01T4GhouLonqYfiv7drttp", "price_1L01RFGhouLonqYfec98SKNk"]

    const switchConfirmation = (values:any) => {
      console.log("------")
      console.log(values)
      const n = state.job.monitor_num
      const l = state.job.images.length
      const a_s = moment(state.job.application_start_datetime)
      const a_e = moment(state.job.application_end_datetime)
      const p_s = moment(state.job.start_post_at)
      const p_e = moment(state.job.end_post_at)

      if(a_s > a_e) {
        Swal.fire({ title: '入力エラー', text: "応募期間の整合性があっていません", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
      } else  if(p_s > p_e) {
        Swal.fire({ title: '入力エラー', text: "応募期間の整合性があっていません", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
      } else if(l == 0) {
        Swal.fire({
          title: '入力エラー',
          text: "写真は一枚以上選択してください",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else if(l > 6) {
        Swal.fire({
          title: '入力エラー',
          text: "写真は6枚以下選択してください",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else {
        if(org.value["plan_id"] == 'price_1JtU67GhouLonqYfmsiPetlT' && n > 10) {
          Swal.fire({
            title: '入力エラー',
            text: "現在のプランだと、10人までしか指定できません",
            icon: 'error',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        } else {
          state.job.category = state.category
          state.job.gender = state.gender
          state.job.experience = state.experience
          state.job.media = state.medias
          state.job.min_age = state.min_age
          state.job.max_age = state.max_age
          state.job.description = state.product.description
          state.product.color_list = state.colorList.join(",")
          state.product.size_list = state.sizeList.join(",")
          state.product.variation_color_stock_json = JSON.stringify(state.sizeColorStock)

          window.scrollTo({ top: 0, behavior: "smooth"});
          state.isConfirmationMode = !state.isConfirmationMode         
        }
      }

    }


    const onFileChange = (e:any) => {
      const files = e.target.files || e.dataTransfer.files;
      if(state.job.images == null) {
        state.job.images = []
      }
      if(files.length > 6) {
        Swal.fire({ title: '入力エラー', text: "画像は6枚までにしてください", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
      }
      const sizeLimit = 1024 * 1024 * 3;

      for(const f in files) {
        const reader = new FileReader();
        reader.onload = (e:any) => {
          state.job.images.push(e.target.result);
        };

        if (files[f].size > sizeLimit) {
          Swal.fire({ title: '入力エラー', text: "ファイルサイズは3MB以下にしてください", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
          return; 
        } else {
          reader.readAsDataURL(files[f])
        }
      }
    }
    const createImage = (file: any)  => {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        state.uploadedImage = e.target.result
      }
      reader.readAsDataURL(file)
    }
    const submitProduct = () => {
      if(state.isProduct == false) {
        product.value.job_id = state.job_uuid
        return store.dispatch("product/createProduct", product)
      } else {
        return store.dispatch("product/updateProduct", product)
      }
    }
    const onChangeArray = (ar:string[], v:string) => {
      if(ar.indexOf(v) == -1) {
        ar.push(v)
      } else {
        ar.splice(ar.indexOf(v), 1)
      }
    }
    const onChangeSelect = (s: string, v:string) => {
      if(s == 'gender') {
        state.gender = v
      } else if(s == 'category') {
        state.category = v
      } else if(s == 'experience') {
        state.experience = v
        onChangeArray(state.medias, 'experience')
      }
    }
    const updateLabel = (type:string, index:number, label:string) => {
      var result:any = {}
      if(type == "color") {
        var o = state.sizeColorStock[label]
        state.colorList.forEach((s, i) => {
          result[s] = (i == index)? o : state.sizeColorStock[s]
        })
      }
      if(type == "size") {
        state.colorList.forEach((c) => {
          result[c] = {}
          state.sizeList.forEach((s, i) => {
            if(i == index) {
              result[c][s] = state.sizeColorStock[c][label]
            } else {
              result[c][s] = state.sizeColorStock[c][s]
            }
          })
        })
      }
      state.sizeColorStock = result
    }
    const updateSumMonitor = () => {
      state.job.monitor_num = 0
      for(var color in state.sizeColorStock) {
        for(var size in state.sizeColorStock[color]) {
          state.job.monitor_num += parseInt(state.sizeColorStock[color][size])
        }
      }
      
    }

    const onSubmitAsDraft = () => {
      state.job.publish_state = "draft"
      //state.job.profile = state.uploadedImage
      state.job.category = state.category
      state.job.gender = state.gender
      state.job.media = state.medias.join(",")
      state.job.min_age = state.min_age
      state.job.max_age = state.max_age
      state.job.description = state.product.description
      state.product.color_list = state.colorList.join(",")
      state.product.size_list = state.sizeList.join(",")
      state.product.variation_color_stock_json = JSON.stringify(state.sizeColorStock)

      Swal.fire({
        icon: 'info',
        title: '投稿中'
      });
      Swal.showLoading();

      store.dispatch("job/updateJob", {job_uuid: state.job_uuid, job: state.job})
        .then((d:any) => {
          state.product.job_id = d.data.uuid

          store.dispatch("product/updateProduct", state.product)
            .then(() => {
              Swal.close()
              Swal.fire({
                title: '下書き保存しました',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
              }).then((result) => {
                if(result.isConfirmed) {
                  router.push({name: "Dashboard", params: {}})
                }
              })


            })
            
        })

    }
    const categories = [
      "アウトドア", "インテリア", "キッチン", "コスメ", "家具家電", "洋服", "おもちゃ", "ハンドメイド", "フード", "こども服", "エンタメ", "スポーツ", "アクセサリー"
    ]
    const onSubmitJob = () => {
      state.job.publish_state = "under_review"
      state.job.description = state.product.description
      state.job.gender = state.gender
      state.job.media = state.medias.join(",")
      state.job.category = state.category
      state.job.description = state.product.description


      store.dispatch("job/updateJob", {job_uuid: state.job_uuid, job: state.job})
        .then((d: any) => {
          if(product.value.id == undefined) {
            product.value.job_id = d.data.uuid

            store.dispatch("product/createProduct", product.value)
              .then(() => {
                router.push({name: "UnderReviewJob", params: { job_uuid: product.value.job_id }})
              })
          } else {
            store.dispatch("product/updateProduct", product.value)
              .then(() => {
                router.push({name: "UnderReviewJob", params: { job_uuid: product.value.job_id }})
              })
          }

        })
    }
    const isAvailableCreate = () => {
      return (freePlans.indexOf(org.value.plan_id) == -1)? true : false
    }
    const isIncludeMedia = (s:string) => {
      return (state.medias.indexOf(s) > -1)? true : false
    }
    const isActive = (ar:string[], s:string) => {
      return (ar.indexOf(s) > -1)? "active-check" : "" 
    }
    const isActiveSelect = (s: string, v:string) => {
      if(s == 'gender') {
        return (state.gender == v)? "active-check" : "" 
      } else if(s == 'category') {
        return (state.category == v)? "active-check" : "" 
      }
    }
    const updateInstagramImageOpt = (opt:string[]) => {
      state.job.instagram_caution_image = opt.join(",") 
    }
    const updateInstagramCaptionOpt = (opt:string[]) => {
      state.job.instagram_caution_caption = opt.join(",") 
    }
    const updateInstagramReccomendHashtag = (opt:string[]) => {
      state.job.recommend_hashtags = opt.join(",") 
    }
    const updateInstagramFeedMustHashtag = (opt:string[]) => {
      state.job.must_hashtags = opt.join(",") 
    }
    const updateInstagramStoryOpt = (opt:string[]) => {
      state.job.instagram_cation_story = opt.join(",") 
    }
    const updateInstagramMustHashtag = (opt:string[]) => {
      state.job.must_story_hashtags = opt.join(",") 
    }
    const updateInstagramReccomendStoryHashtag = (opt:string[]) => {
      state.job.recommend_story_hashtags = opt.join(",") 
    }
    const updatePostNum = (n: number) => {
      state.job.instagram_post_num = n
    }
    const updateStoryPostNum = (n: number) => {
      state.job.instagram_story_post_num = n
    }
    const updateTwitterPostNum = (n: number) => {
      state.job.twitter_post_num = n
    }
    const updateTwitterImageOpt = (opt:string[]) => {
      state.job.twitter_caution_image = opt.join(",") 
    }
    const updateTwitterCaptionOpt = (opt:string[]) => {
      state.job.twitter_caution_text = opt.join(",") 
    }
    const updateTwitterMustHashtag = (opt:string[]) => {
      state.job.twitter_must_hashtags = opt.join(",") 
    }
    const updateTwitterReccomendHashtag = (opt:string[]) => {
      state.job.twitter_recommend_hashtags = opt.join(",") 
    }

    const addVariation = () => {
      if(state.colorList.includes("カラー")) {
        Swal.fire({
          title: '入力エラー',
          text: "「カラー」は使えません。他の表記に変更してください",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else {
        state.colorList.push("カラー")
        initialSizeColor()
      }

    }
    const deleteVariation = (t:string) => {
      var r:string[] = []
      for(var i in state.colorList) {
        if(state.colorList[i] != t) {
          r.push(state.colorList[i])
        }
      }
      state.colorList = r
      initialSizeColor()
    }
    const deleteSize = (t:string) => {
      var r:string[] = []
      for(var i in state.sizeList) {
        if(state.sizeList[i] != t) {
          r.push(state.sizeList[i])
        }
      }
      state.sizeList = r
      initialSizeColor()
    }
    const addSize = () => {
      if(state.sizeList.includes("サイズ")) {
        Swal.fire({
          title: '入力エラー',
          text: "「サイズ」は使えません。他の表記に変更してください",
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      } else {
        state.sizeList.push("サイズ")
        initialSizeColor()
      }
    }

    const initialSizeColor = () => {
      var r:any = {}
      state.colorList.forEach((c) => {
        if(r[c] == undefined) {
          r[c] = {}
        } 
      })
      state.colorList.forEach((c) => {
        state.sizeList.forEach((s) => {
          if(state.sizeColorStock[c] != undefined) {
            r[c][s] = state.sizeColorStock[c][s]
          } else if(r[c][s] == undefined || r[c][s] == null) {
            r[c][s] = 0
          }
        })
      })
      state.sizeColorStock = r

      state.colorList.forEach((c) => {
        state.sizeList.forEach((s) => {
          if(state.sizeColorStock[c][s] == null) {
            state.sizeColorStock[c][s] = 0
          }
        })
      })
      state.sizeColorStock = Object.assign({}, state.sizeColorStock, state.sizeColorStock)
    }

    const setSize = () => {
      if(state.job.is_size == 'false') {
        state.sizeList = ["フリーサイズ"]
        initialSizeColor()
      }
    }
    const setVariation = () => {
      if(state.job.is_variation == 'false') {
        state.colorList = ["ワンカラー"]
        initialSizeColor()
      }
    }

    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
      store.dispatch('user/fetchUser')
      store.dispatch('job/job', state.job_uuid)
        .then((res) => {
          const j = res.data.job
          const p = res.data.products[0]

          state.product = res.data.products[0]
          state.job = j
          state.job.medias = j.media.split(",")
          if(j.images == "") {
            state.job.images = []
          } else {
            state.job.images = j.images.split(",")
          }

          state.medias = j.media.split(",")
          state.category = j.category
          state.gender = j.gender
          state.min_age = j.min_age
          state.max_age = j.max_age
          state.job.is_size = j.is_size.toString()
          state.job.is_variation = j.is_variation.toString()
          state.isPamphlet = j.is_pamphlet

          state.job.application_start_datetime = moment(j.application_start_datetime).format("YYYY-MM-DD")
          state.job.application_end_datetime = moment(j.application_end_datetime).format("YYYY-MM-DD")
          state.job.selection_datetime = moment(j.selection_datetime).format("YYYY-MM-DD")

          if(j["is_size"] == true || j["is_size"] == "true" || j["is_variation"] == true || j["is_variation"] == "true") {
            state.sizeColorStock  = JSON.parse(p["variation_color_stock_json"])
            state.sizeList = p["size_list"].split(",")
            state.colorList = p["color_list"].split(",")
          }

        })

      state.product = product
      if(product.value.length == 0) {
        state.isProduct = false
      } else {
        state.isProduct = true
      }
    })
    const removeImage = (i:number) => {
      state.job.images.splice(i, 1)
    }
    return {
      onSubmitJob, onSubmitAsDraft, createImage, switchConfirmation, state, onFileChange, job, product, submitProduct, schema, olps,
      isIncludeMedia, isActive, isActiveSelect, updateInstagramImageOpt, updateInstagramCaptionOpt, updateInstagramReccomendHashtag,
      updateInstagramStoryOpt, updateInstagramMustHashtag, updateInstagramReccomendStoryHashtag,
      updateTwitterImageOpt, updateTwitterCaptionOpt, updateTwitterMustHashtag, updateTwitterReccomendHashtag, updateInstagramFeedMustHashtag, updatePostNum,
      categories, onChangeArray, onChangeSelect, updateLabel, updateSumMonitor, removeImage, addVariation, addSize, setSize, setVariation,
      deleteVariation, deleteSize, isAvailableCreate, freePlans, org, updateTwitterPostNum, updateStoryPostNum

    }
  }
})
