
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import Header from "@/components/common/Header.vue"
import SelectInfluencerTable from "@/components/jobs/SelectInfluencerTable.vue"

interface State {
  state: string;
  job_uuid: string | string[];
}

export default defineComponent({
  components: {Header, SelectInfluencerTable},
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)
    const autoSelectInfluencers = computed(() => store.state.applicate.autoSelectInfluencers)
    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"],
    })
    const arraySplit = (s: any) => {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    }
    const goBack = () => {
      router.go(-1)
    }
    const refreshSelect = (s: string) => {
      const m = s || "follower"
      store.dispatch('applicate/fetchAutoSelection', {job_uuid: state.job_uuid, m: m})
    }
    const jumpAutoSelect = (s: string) => {
      window.scrollTo({ top: 0, behavior: "smooth"});
      router.push({name: "AutoSelect", params: { job_uuid: state.job_uuid }, query: {m:s}})
    }
    const jumpManualSelectPage = () => {
      window.scrollTo({ top: 0, behavior: "smooth"});
      router.push({name: "ManualSelectList", params: { job_uuid: state.job_uuid }})
    }
    const definitionInfluencer = () => {
      Swal.fire({
        title: 'インフルエンサーを確定しますか？',
        text: "確定すると、インフルエンサーに通知され、荷物が発送されます",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        reverseButtons: true
      }).then((result) => {
        if(result.isConfirmed) {
          for(const i in autoSelectInfluencers.value) {
            const inf = autoSelectInfluencers.value[i]
            store.dispatch('applicate/updateApplicate', {applicate_id: inf.id, s: 'applied', job_id: state.job_uuid})
          }

          store.dispatch('job/updateJob', {job_uuid: state.job_uuid, job: {publish_state: "selected"}})
            .then(() => {
              console.log("------------------")
              router.push({name: "DefinitionInfluencer", params: { job_uuid: state.job_uuid }})
            })
        }
      })
    }

    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
      const m = route.query["m"] || "follower"
      store.dispatch('applicate/fetchAutoSelection', {job_uuid: state.job_uuid, m: m})
    })
    return {
      job, product, arraySplit, autoSelectInfluencers, definitionInfluencer, jumpAutoSelect, goBack, jumpManualSelectPage, refreshSelect
    }
  }
})
