
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    currentStep: String,
    product: Object,
  },
  setup (props) {
    const isCurrent = (s: string) => {
      return (s == props.currentStep)? true : false
    }
    const isCurrentClass = (s: string) => {
      return (s == props.currentStep)? 'ProgressBar-step--active hide-label-down' : ''
    }
    onMounted(async () => {})

    const downloadOpenLogi = (p: any) => {
      if(p == null) {
        return 
      }
      window.open((p.openlogi_url || ""), '_blank');
      
    }

    return { isCurrent, isCurrentClass, downloadOpenLogi }
  }
})
