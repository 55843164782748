
import { defineComponent, onMounted, inject, computed } from 'vue'
import { useAuth0, state } from "../auth/index.js";
import { useStore } from 'vuex'

const { loginAuth, logoutAuth, initAuth } = useAuth0(state);
initAuth();

export default defineComponent({
  components: {},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)

    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })

    const login = () => {
      loginAuth()
    }
    const logout = () => {
      logoutAuth()
    }
    return {
      login, logout, user, org, auth
    }
  }
})

