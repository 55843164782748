import { createStore } from "vuex"
import axios from 'axios'

const state ={
  jobs: [],
  job: {
    category: "",
    instagram_caution_image: "",
    instagram_caution_caption: "",
    must_hashtags: "",
    recommend_hashtags: "",
    media: [],
  },
  product: {},
  applicates: [],
  olps: []
}
const actions = {
  fetchJobs({commit}: {commit: any}, payload: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs?mode=" + (payload.mode || "processing") + "&word=" + (payload.word || ""), headers)
      .then((res: any) => {
        commit("setJobs", res.data.jobs)
      })
  },
  fetchJob({commit}: {commit: any}, job_id:string) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + job_id, headers)
      .then((res: any) => {
        commit("setJob", res.data.job)
        commit("setProduct", res.data.products[0])
        commit("setOlps", res.data.olps)
        commit("setApplicates", res.data.applicates)
      })
  },
  duplicateJob({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/job/duplicate?job_id=" + payload.job_id, {}, headers)  

  },
  fetchApplicates({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_id + "/applicates?size=" + payload.size + "&color=" + payload.color, headers)  
      .then((res: any) => {
        commit("setApplicates", res.data.applicates)
      })
  },
  job({commit}: {commit: any}, job_id:string) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + job_id, headers)
  },
  updateJob({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}

    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_uuid, {job: payload.job}, headers)
  },
  createJob({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs", {job: payload}, headers)
  },
  destroyJob({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .delete(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_uuid, headers)
  }

  //submitCreateStaff({commit}: {commit: any}, staff: any) {
  //  const idToken: string | null = localStorage.getItem("idToken")
  //  axios
  //    .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs", {staff: staff} , {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
  //    .then((res: any) => {
  //      commit("addStaff", res.data)
  //    }).catch((e) => {
  //      console.log("----")
  //      console.log(e)
  //    })
  //}
}
const mutations = {
  setApplicates(state: any, applicates: object) {
    state.applicates = applicates
  },
  setJobs(state: any, jobs: object) {
    state.jobs = jobs
  },
  setOlps(state: any, olps: any) {
    state.olps = olps
  },
  setJob(state: any, job: object) {
    state.job = job
    if(state.job.media == null) {
      state.job.media = []
    } else {
      state.job.media = state.job.media.split(',')
    }
  },
  setProduct(state: any, product: object) {
    state.product = product
  },
  //addStaff(state: any, staff: object) {
  //  state.staffs.push(staff)
  //}
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

