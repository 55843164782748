
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import ConfirmationJob from "@/components/jobs/ConfirmationJob.vue"
import PostSchejule from "@/components/jobs/preview/PostSchejule.vue"
import InfluencerEstimate from "@/components/jobs/preview/InfluencerEstimate.vue"
import ProgressBar from "@/components/jobs/ProgressBar.vue"
import Swal from 'sweetalert2'
import moment from 'moment'

interface State {
  job_uuid: string | string[]
}

export default defineComponent({
  components: {Header, ConfirmationJob, PostSchejule, InfluencerEstimate,  ProgressBar},
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)

    const state = reactive<State>({
      job_uuid: route.params["job_uuid"]
    })
    const formatArriveDate = () => {
      const d = moment(job.value["selection_datetime"]).add(-3, 'd')
      const w = ['日', '月', '火', '水', '木', '金', '土']
      return d.format("M/D") + "(" + w[parseInt(d.format("d"))] + ")"
    }


    const confirmCheckoutPublish = () => {
      Swal.fire({
        title: '募集を開始しますか？',
        text: "開始すると、配送料が登録クレジットカードから決済されます",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '募集を開始',
        cancelButtonText: 'キャンセル',
        reverseButtons: true,
      }).then((result) => {
        if(result.isConfirmed) {
          store.dispatch('job/updateJob', {job_uuid: state.job_uuid, job: {publish_state: "published"}})
            .then(() => {
              router.push({name: "PublishedJob", params: { job_uuid: state.job_uuid }})
            })
        }
      })
    }
    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
    })
    return {
      job, confirmCheckoutPublish, store, product, formatArriveDate
    }
  }
})
