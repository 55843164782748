import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import Callback from "../views/Callback.vue"
import GettingStarted from "../views/GettingStarted.vue"
import Dashboard from "../views/Dashboard.vue"

//Organizations
import UnderReview from "../views/organizations/UnderReview.vue"
import Rejected from "../views/organizations/Rejected.vue"
import CompleteCancellation from "../views/organizations/CompleteCancellation.vue"

// Jobs
import CreateJob from "../views/jobs/CreateJob.vue"
import JobForm from "../views/jobs/JobForm.vue"
import UnderReviewJob from "../views/jobs/UnderReviewJob.vue"
import PassedJob from "../views/jobs/PassedJob.vue"
import PublishedJob from "../views/jobs/PublishedJob.vue"
import Recruiting from "../views/jobs/Recruiting.vue"
import Select from "../views/jobs/Select.vue"
import AutoSelect from "../views/jobs/AutoSelect.vue"
import ManualSelectList from "../views/jobs/ManualSelectList.vue"
import ManualSelect from "../views/jobs/ManualSelect.vue"
import DefinitionInfluencer from "../views/jobs/DefinitionInfluencer.vue"
import PostConfirm from "../views/jobs/PostConfirm.vue"

// Settings
import Settings from "../views/settings/Settings.vue"
import Profile from "../views/settings/Profile.vue"
import Staffs from "../views/settings/Staffs.vue"
import Logout from "../views/settings/Logout.vue"
import Plan from "../views/settings/Plan.vue"
import History from "../views/settings/History.vue"
import Favorites from "../views/settings/Favorites.vue"
import Warehouse from "../views/Warehouse.vue"
import Withdraw from "../views/settings/Withdraw.vue"
import { routeGuard } from "@/auth"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/callback",
    name: "Callback",
    component: Callback,
  },
  {
    path: "/getting_started",
    name: "GettingStarted",
    component: GettingStarted,
    beforeEnter: routeGuard,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: routeGuard,
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: Warehouse,
    beforeEnter: routeGuard,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/plan",
    name: "Plan",
    component: Plan,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/staffs",
    name: "Staffs",
    component: Staffs,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/favorites",
    name: "Favorites",
    component: Favorites,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/withdraw",
    name: "Withdraw",
    component: Withdraw,
    beforeEnter: routeGuard,
  },

  {
    path: "/setting/logout",
    name: "Logout",
    component: Logout,
    beforeEnter: routeGuard,
  },
  {
    path: "/setting/history",
    name: "History",
    component: History,
    beforeEnter: routeGuard,
  },

  {
    path: "/jobs/new",
    name: "CreateJob",
    component: CreateJob,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/under_review_job",
    name: "UnderReviewJob",
    component: UnderReviewJob,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/form",
    name: "JobForm",
    component: JobForm,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/passed_job",
    name: "PassedJob",
    component: PassedJob,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/published_job",
    name: "PublishedJob",
    component: PublishedJob,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/recruiting",
    name: "Recruiting",
    component: Recruiting,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/select",
    name: "Select",
    component: Select,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/auto_select",
    name: "AutoSelect",
    component: AutoSelect,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/manual_select_list",
    name: "ManualSelectList",
    component: ManualSelectList,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/manual_select",
    name: "ManualSelect",
    component: ManualSelect,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/definition",
    name: "DefinitionInfluencer",
    component: DefinitionInfluencer,
    beforeEnter: routeGuard,
  },
  {
    path: "/jobs/:job_uuid/post_confirm",
    name: "PostConfirm",
    component: PostConfirm,
    beforeEnter: routeGuard,
  },

  {
    path: "/under_review",
    name: "UnderReview",
    component: UnderReview,
    beforeEnter: routeGuard,
  },
  {
    path: "/rejected",
    name: "Rejected",
    component: Rejected,
    beforeEnter: routeGuard,
  },
  {
    path: "/complete_cancellation",
    name: "CompleteCancellation",
    component: CompleteCancellation,
    beforeEnter: routeGuard,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
