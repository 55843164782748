
import { defineComponent, onMounted, reactive, watch } from 'vue'

interface State {
  imageOption: string[];
  textOption: string[];
  mustHashtag: string[];
  selectHashtag: string[];
  mHashtag: string;
  twitterPostNum: number;
}

export default defineComponent({
  props: {
    job: Object,
  },
  setup(props, context) {
    const state = reactive<State>({
      imageOption: [],
      textOption: [],
      mustHashtag: ['mereco', 'pr'],
      selectHashtag: [],
      mHashtag: "",
      twitterPostNum: 0
    })

    const selectNewLists = [
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
    ]
    const hashtagLists = [
      {label: "#pr", type: "input"},
      {label: "#mereco", type: "input"},
      {label: "", type: "new"},
    ]
    const instagramOptionSelection = [
      {label: "弊社アカウントをメンションしてください", type: "normal", prefix: ""},
      {label: "ロゴ・商品パッケージを必ず入れてください", type: "normal", prefix: ""},
      {label: "実際に使用しているシーンを撮影ください", type: "normal", prefix: ""},
      {label: "顔出し必須でお願いします", type: "normal", prefix: ""},
      {label: "商品の色味がわからなくなる画像補正はしないでください", type: "normal", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
    ]
    const instagramCaptionOptionSelection = [
      {label: "弊社アカウントをメンションしてください", type: "normal", prefix: ""},
      {label: "実際に使用しているシーンを撮影ください", type: "normal", prefix: ""},
      {label: "顔出し必須でお願いします", type: "normal", prefix: ""},
      {label: "商品の色味がわからなくなる画像補正はしないでください", type: "normal", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
    ]
    const changeOpt = (t:string, opt:string[], s:string) => {
      if(opt.indexOf(s) == -1) {
        opt.push(s)
      } else {
        opt.splice(opt.indexOf(s), 1)
      }
      if(t == 'image') {
        context.emit('updateImageOpt', opt)
      } else if(t == 'caption') {
        context.emit('updateTextOpt', opt)
      } else if(t == 'must_hash') {
        context.emit('updateMustHash', opt)
      } else if(t == 'hash') {
        context.emit('updateReccomendHash', opt)
      }
    }
    const changePostNum = () => {
      context.emit('updatePostNum', state.twitterPostNum)
    }
    const isCheck = (opt:string[], s:string) => {
      if(opt.indexOf(s) == -1) {
        return false
      } else {
        return true
      }     
    }
    const updateCaption = (t: string, opt:string[], l:string, i:number) => {
      opt[i] = l
      if(t == 'image') {
        context.emit('updateImageOpt', opt)
      } else if(t == 'caption') {
        context.emit('updateTextOpt', opt)
      }
    }
    const isAvailable = (i:number) => {
      const l = state.selectHashtag.length 
      if(l >= i) {
        return false
      } else {
        return true
      }
    }
    const updateReccomendHashtagInput = (l:string, i:number) => {
      const t = l.replace('#', '').replace(' ','')
      state.selectHashtag[i] = t
      context.emit('updateRecommendHash', state.selectHashtag)
    }
    const updateMustHashtagInput = () => {
      const t = state.mHashtag.replace('#', '').replace(' ','')

      state.mustHashtag = ['mereco', 'pr', t]
      context.emit('updateMustHash', ['mereco', 'pr', t])
    }


    watch(props, () => {
      state.imageOption = castArray(props.job?.twitter_caution_image)
      state.textOption = castArray(props.job?.twitter_caution_text)
      state.mustHashtag = castArray(props.job?.twitter_must_hashtags)
      if(state.mustHashtag.length == 0) {
        state.mustHashtag = ['mereco', 'pr']
        state.mHashtag = ""
        //context.emit('updateMustHash', ['ouvo', 'pr'])
      } else {
        state.mustHashtag = props.job?.twitter_must_hashtags.split(",")
        state.mHashtag = state.mustHashtag.slice(-1)[0]

        //context.emit('updateMustHash', castArray(props.job?.twitter_must_hashtags))
      }
      state.twitterPostNum = props.job?.twitter_post_num
      state.selectHashtag = castArray(props.job?.twitter_recommend_hashtags)
      state.selectHashtag.forEach((h,i) => {
        selectNewLists[i]["label"] = h
      })
    })
    const castArray = (s: string) => {
      if(s == "" || s == null) {
        return []
      } else {
        return s.split(",")
      }
    }
    const isAvailableFree = (i:number, opt:string[]) => {
      if(opt == undefined) {
        return false
      }
      if(opt.length >= i) {
        return false
      } else {
        return true
      }
    }
    onMounted(async () => {})
    return {
      instagramOptionSelection, instagramCaptionOptionSelection, hashtagLists, selectNewLists, changeOpt, isCheck, state,
      updateMustHashtagInput, updateReccomendHashtagInput, isAvailable, castArray, updateCaption, isAvailableFree, changePostNum
    }  
  }
})
