
import { reactive, defineComponent, onMounted, inject, computed } from 'vue'
import { useStore } from 'vuex'
import config from "@/const/const"
import Header from "@/components/common/Header.vue"
import moment from 'moment'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

interface StringKeyObject {
  [key: string]: any;
}

export default defineComponent({
  components: {Header},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)
    const jobs = computed(() => store.state.job.jobs)
    const notifications = computed(() => store.state.notification.notifications)

    const state = reactive({
      currentTab: "processing",
      freeword: ""
    })

    const stateDict:StringKeyObject = {
      "": "",
      "draft": "下書き",
      "under_review": '審査中',
      "rejected": '情報不備',
      "passed": '審査通過',
      "published": '公開中',
      "selected": '投稿確認',
      "completed": '終了',
    }

    onMounted(async () => {
      store.dispatch('job/fetchJobs', {mode: 'processing'})
      store.dispatch('user/fetchUser')
      store.dispatch('notification/fetchNotifications')

      if(org.value.state == "under_review") {
        router.push({ name: "UnderReview" })
      } else if(org.value.state == "rejected") {
        router.push({ name: "Rejected" })
      }
    })

    const changeTab = (s: string) => {
      store.dispatch('job/fetchJobs', {mode: s})
      state.currentTab = s
    }
    const toCreateJob = () => {
      router.push({ name: "CreateJob" })
    }

    const dateFormat = (j: any) => {
      return moment(j.end_post_at).format("YYYY.MM.DD")
    }

    const dateDiff = (j: any) => {
      return moment(j.application_end_datetime).diff(moment(), 'days')
    }
    const hoursDiff = (j: any) => {
      return moment(j.application_end_datetime).diff(moment(), 'hours')
    }

    const getImage = (j: any) => {
      if(j.images == undefined) {
        return require("@/assets/product.png")
      } else if(j.images == "") {
        return require("@/assets/product.png")
      } else {
        return j.images.split(',')[0]
      }
    }

    const stateTitle = (j:StringKeyObject) => {
      var s = j.publish_state as string
      if(s == "complete") {
        return "完了"
      } else if (j.publish_state == config.JOB.UNDER_REVIEW) {
        return "審査中"
      } else if (j.publish_state == config.JOB.PASSED) {
        return "審査通過"
      } else if (j.publish_state == config.JOB.DRAFT) {
        return "下書き"
      } else if(dateDiff(j) < 0) {
        return "募集完了"
      } else {
        return stateDict[s]
      }
    }

    const stateMessage = (j: any) => {
      if (j.publish_state == config.JOB.UNDER_REVIEW) {
        return "審査中です"
      } else if (j.publish_state == config.JOB.PASSED) {
        return "募集を開始してください"
      } else if (j.publish_state == config.JOB.SELECTED) {
        return "投稿を確認してください"
      } else if (j.publish_state == config.JOB.PUBLISHED) {
        if(dateDiff(j) < 0) {
          return "インフルエンサーを選定してください"
        } else {
          if(dateDiff(j) == 0) {
            if(hoursDiff(j) < 0) {
              return "インフルエンサーを選定してください"
            } else {
              return "募集終了まであと" + hoursDiff(j) +  "時間"
            }
          } else {
            return "募集終了まであと" + dateDiff(j) +  "日"
          }
        }
      }
    }

    const jobStateLink = (j: any) => {
      if (j.publish_state == config.JOB.UNDER_REVIEW) {
        return "jobs/" + j.uuid + "/under_review_job"
      } else if (j.publish_state == config.JOB.DRAFT) {
        return "jobs/" + j.uuid + "/form"
      } else if (j.publish_state == config.JOB.PASSED) {
        return "jobs/" + j.uuid + "/passed_job"
      } else if (j.publish_state == config.JOB.REJECTED) {
        return "jobs/" + j.uuid + "/form"
      } else if (j.publish_state == config.JOB.SELECTED) {
        return "jobs/" + j.uuid + "/post_confirm"
      } else if (j.publish_state == config.JOB.PUBLISHED) {
        var n = moment()
        var application_s_at = moment(j.application_start_datetime)
        var application_e_at = moment(j.application_end_datetime)
        if (n.isBetween(application_s_at, application_e_at)) {
          return "jobs/" + j.uuid + "/published_job"
        } else if (n.isAfter(application_e_at)) {
          return "jobs/" + j.uuid + "/select"
        } else if (n.isBefore(application_s_at)) {
          return "jobs/" + j.uuid + "/published_job"
        }
      } else if (j.publish_state == config.JOB.COMPLETED) {
        return "jobs/" + j.uuid + "/post_confirm"
      }
    }
    const duplicateJob = (j:any) => {
      Swal.fire({
        title: '案件を複製しますか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '複製',
        reverseButtons: true,
      }).then((result) => {
        if(result.isConfirmed) {
          store.dispatch('job/duplicateJob', {job_id: j.uuid})
            .then((r) => {
              console.log(r)
              router.push({ name: "JobForm", params: {job_uuid: r.data.job.uuid} })
            })
        }
      })

    }
    const freewordSearch = () => {
      store.dispatch('job/fetchJobs', {mode: state.currentTab, word: state.freeword})
        .then((r) => {
          console.log(r)
          store.dispatch('job/fetchJobs', state.currentTab)
        })
    }
    const deleteJob = (j:any) => {
      Swal.fire({
        title: '案件を削除しますか？',
        text: "削除すると、元には戻せません",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '削除',
        reverseButtons: true,
      }).then((result) => {
        if(result.isConfirmed) {
          store.dispatch('job/destroyJob', {job_uuid: j.uuid})
            .then((r) => {
              console.log(r)
              store.dispatch('job/fetchJobs', {mode: state.currentTab})
            })
        }
      })
    }

    const logout = () => {
      auth.logout({
        returnTo: window.location.origin,
      })
    }
    return {
      logout, 
      user, org, jobs, jobStateLink,
      stateTitle,
      dateFormat,
      stateMessage,
      changeTab,
      state,
      toCreateJob,
      getImage,deleteJob, hoursDiff,
      duplicateJob,
      freewordSearch,
      notifications
    }
  }
})

