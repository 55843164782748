
import { defineComponent, onMounted, reactive, computed } from 'vue'
import axios from "axios"
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const jsonpAdapter = require('axios-jsonp')
import Swal from 'sweetalert2'
import "@/assets/css/getting_started.css";

interface State {
  org: any;
  cardNumber: string;
  cardMonth: string;
  cardYear: string;
  cardCvc: string;
  selectPlan: string;
  confirmMode: boolean;
}

export default defineComponent({
  components: { Field, Form, ErrorMessage},
  setup () {
    const store = useStore()
    const phoneRegExp = /^0\d{1,4}-\d{1,4}-\d{3,4}$/
    const schema = yup.object({
      name: yup.string().required("会社名は必須です"),
      phone: yup.string().matches(phoneRegExp, '電話番号の形式に誤りがあります').required("電話番号は必須です"),
      zip: yup.string().required("郵便番号は必須です"),
      address1: yup.string().required("住所は必須です"),
      card_number: yup.string().required("カード番号は必須です"),
      card_cvc: yup.string().required("CVCコードは必須です"),
    });
    const planDict = {
      "price_1L01T4GhouLonqYfiv7drttp": "無料契約プラン",
      "price_1L01RFGhouLonqYfec98SKNk": "倉庫保管プラン(契約満期時に1ヶ月自動更新プラン)",
      "price_1JtU67GhouLonqYfmsiPetlT": "ワンショットプラン(契約満期時に利用できなくなります)",
      "price_1JtU59GhouLonqYfZurbVHiL": "有料プラン(契約満期時に3ヶ月自動更新プラン)",
      "price_1JtU59GhouLonqYfEg8AVIcr": "有料プラン(契約満期時に6ヶ月自動更新プラン)",
      "price_1JtU59GhouLonqYflsN51Gk0": "有料プラン(契約満期時に1年自動更新プラン)",
    }
    const state = reactive<State>({
      org: {},
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvc: "",
      selectPlan: "price_1JtU67GhouLonqYfmsiPetlT",
      confirmMode: false
    })
    const router = useRouter()
    const user = computed(() => store.state.user.user)

    const activeClass = (s: string) => {
      if(s == state.selectPlan) {
        return "active-plan"
      }
    }
    const switchPlan = (s: string) => {
      state.selectPlan = s
    }
    const searchAddress = () => {
      axios.get(`https://api.zipaddress.net/?zipcode=${state.org.zip}`, {adapter: jsonpAdapter}).then((rs:any) => {

        console.log(rs)
        const response = rs.data
        if(response.code != null) {
          Swal.fire({ title: '入力エラー', text: "郵便番号が正しくありせん", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})

        } else {
          state.org.address1 = response.pref + response.city + response.town
        }
      })
    }
    const switchConfirmation = () => {
      state.confirmMode = !state.confirmMode
    }
    const onSubmitOrganization = () => {
      const idToken: string | null = localStorage.getItem("idToken")
      const headers = {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        }
      }
      const card = {
        number: state.cardNumber,
        month: state.cardMonth,
        year: state.cardYear,
        cvc: state.cardCvc
      }
      axios
        .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs/" + user.value['id'], {staff: user.value}, headers)
        .then(() => {
          axios
            .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/stripe", {card: card}, headers)
            .then((res:any) => {
              console.log(res.data)
              const ct = res.data.id
              const c = {card_token: ct, tokens: res.data.card.id, last4: res.data.card.last4, brand: res.data.card.brand}
              state.org.plan = state.selectPlan
              axios
                .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/organizations", state.org, headers)
                .then(() => {
                  store.dispatch('card_token/createCardToken', c)
                    .then(() => {
                      router.push({ name: "UnderReview" })
                    })
                })
            }).catch(() => {
              router.push({ name: "UnderReview" })
            })



        })
    }

    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })

    return {
      schema, onSubmitOrganization, state, user, activeClass, switchPlan, searchAddress, switchConfirmation, planDict
    }
  }
})

