
import { createStore } from "vuex"
import axios from 'axios'

const state ={
  warehouses: [],
}
const actions = {
  fetchWarehouse({commit}: {commit: any}, payload: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/warehouse?word=" + (payload.word || ""), headers)
      .then((res: any) => {
        commit("setWarehouses", res.data.results)
      }).catch((err) => {
        if (err.response.status == 301) {
          //this.$router.push({ name: "GettingStarted" })
          console.log("----")
          console.log("301")
        }
      })
  },
  returnWarehouse({commit}: {commit: any}, payload: any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/warehouse/" + payload.w_id + "?type=" + payload.w_type, {warehouse: {}}, headers)
      .then((res: any) => {
        commit("setWarehouses", res.data.results)
      }).catch((err) => {
        if (err.response.status == 301) {
          //this.$router.push({ name: "GettingStarted" })
          console.log("----")
          console.log("301")
        }
      })
  }
}
const mutations = {
  setWarehouses(state: any, warehouses: any) {
    state.warehouses = warehouses
  },
}
const getters = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

