
import { defineComponent, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import Swal from 'sweetalert2'

interface State {
  state: string;
  job_uuid: string | string[];
  applied_applicates: any;
}

export default defineComponent({
  components: {},
  props: {
    job: Object,
    applicates: Array,
  },
  setup (props) {
    //const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const router = useRouter()

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"],
      applied_applicates: []
    })
    const applicateMap = {
      "first": "出荷準備中",
      "asking": "商品取り寄せ中",
      "in_transit": "配送中",
    }
    const calcAge = (d:string) => {
      const dateOfBirth = moment(d); 
      const today = moment(); 

      let baseAge = today.year() - dateOfBirth.year(); 
      let birthday = moment(
        new Date(today.year() + "-" + (dateOfBirth.month() + 1) + "-" + dateOfBirth.date())
      );

      if (today.isBefore(birthday)) {
        return baseAge - 1; 
      }
      return baseAge;
    }
    const isIncludeMedia = (s:string) => {
      var st = arraySplit(props.job?.media)
      if(st != undefined) {
        return (st.indexOf(s) > -1)? true : false
      }
    }
    const arraySplit = (s: any) => {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    }
    const approveApplicate = (id: string) => {
      Swal.fire({
        title: '投稿を承認しますか？',
        text: "承認すると、インフルエンサーに通知され、案件が完了します",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '承認'
      }).then((result) => {
        if(result.isConfirmed) {
          store.dispatch('applicate/updatePostState', {applicate_id: id, s: 'posted', job_id: state.job_uuid})
            .then(() => {
              router.push({name: "PostConfirm", params: { job_uuid: state.job_uuid }})
            })
        }
      })
    }
    const repostApplicate = (id: string) => {
      Swal.fire({
        title: '再投稿依頼をしますか？',
        text: "再投稿依頼すると、インフルエンサーに通知され、 再投稿をしてもらえます",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '再投稿依頼'
      }).then((result) => {
        if(result.isConfirmed) {
          store.dispatch('applicate/updatePostState', {applicate_id: id, s: 're-post', job_id: state.job_uuid})
            .then(() => {
              router.push({name: "PostConfirm", params: { job_uuid: state.job_uuid }})
            })
        }
      })
    }
    watch(props, () => {
      props.applicates?.forEach((a:any) => {
        if(a["applicate_state"] == 'applied' || a["applicate_state"] == 'completed') {
          state.applied_applicates.push(a)
        }
      })
    })

    onMounted(async () => {})

    return {
      state, calcAge, arraySplit, isIncludeMedia, applicateMap, approveApplicate, repostApplicate
    }
  }
})
