
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

export default defineComponent({
  props: {
    state: Object,
    job: Object,
    category:  String,
  },
  setup (props) {
    const store = useStore()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)

    const currentCarousel = ref(1)

    const mediaDict:any = {
      "post": "Instagram通常投稿",
      "story": "Instagramスト―リーズ投稿",
      "twitter": "Twitter通常投稿",
      "experience": "来店・体験",
    }

    const dateFormat = (d:string) => {
      return moment(d).format("Y/M/D")
    }
    const isAttrBlank = (s:any) => {
      if(s == "") {
        return true
      } else if(s == null) {
        return true
      } else {
        return false
      }
    }
    const isBlank = (s:any) => {
      if(s == "") {
        return "blank-font-color"
      } else if(s == null) {
        return "blank-font-color"
      } 
    }
    const isIncludeMedia = (s:string) => {
      var st = props.state
      if(st != undefined) {
        if(st.medias != undefined) {
          return (st.medias.indexOf(s) > -1)? true : false
        }
      }
    }
    const onChangeImage = (i:number) => {
      currentCarousel.value = i
      console.log("--")
      console.log(currentCarousel)
    }
    const isActiveClass = (i: number, c: number) => {
      return (i == c)? "active" : ""
    }
    const arraySplit = (s: any) => {
      if(s == undefined) {
        return []
      } else if(Array.isArray(s)) {
        return s
      } else {
        return s.split(',')
      }
    }


    const checkBoxWrapperClass = (s:string) => {
      var st = props.state
      if(st != undefined) {
        if(st.medias != undefined) {
          return (st.medias.indexOf(s) > -1)? "post-status-preview" : "post-status-preview__item--uncheck"
        }
      }
    }
    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })

    return { 
      isBlank, isIncludeMedia, checkBoxWrapperClass, arraySplit, mediaDict, isActiveClass, dateFormat,
      org, user, isAttrBlank, onChangeImage, currentCarousel 
    }
  }
})
