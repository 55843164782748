
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import SelectInfluencerTable from "@/components/jobs/SelectInfluencerTable.vue"
import Swal from 'sweetalert2'

interface State {
  state: string;
  job_uuid: string | string[];
  size_stock: any;
  size: any;
  color: any;
  isConfirmation: boolean;
  selectedApplicates: any;
}

export default defineComponent({
  components: {Header, SelectInfluencerTable},
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)
    const applicates = computed(() => store.state.job.applicates)
    const all_applicates = computed(() => store.state.applicate.all_applicates)

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"],
      size_stock: {},
      size: "",
      color: "",
      isConfirmation: false,
      selectedApplicates: []
    })

    const updateInfluencers = (c:string, s: string) => {
      state.size = s
      state.color = c
      store.dispatch('job/fetchApplicates', {job_id: state.job_uuid, size: s, color: c})
    }
    
    const isCheck = (c: string, s:string) => {
      if(c == state.color && s == state.size) {
        return true
      } else {
        return false
      }
    }
    const definitionInfluencer = () => {
      Swal.fire({
        title: 'インフルエンサーを確定しますか？',
        text: "確定すると、インフルエンサーに通知され、荷物が発送されます",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定'
      }).then((result) => {
        if(result.isConfirmed) {
          for(const i of state.selectedApplicates) {
            store.dispatch('applicate/updateApplicate', {applicate_id: i.id, s: 'applied', job_id: state.job_uuid})
          }
          router.push({name: "ManualSelectList", params: { job_uuid: state.job_uuid }, query: { mode: "selected"} })
        }
      })
    }


    const confirmInfluencers = () => {
      if(state.isConfirmation == false) {
        const n = state.size_stock[state.color][state.size]
        const c = state.selectedApplicates.length
        if(c == 0) {
          Swal.fire({
            title: '1人以上は選択してください',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else if(c > n) {
          Swal.fire({
            title: '選択した数が募集人数を超えています。',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } else {
          state.isConfirmation = !state.isConfirmation
        }
      } else {
        state.isConfirmation = !state.isConfirmation
      }
    }
    const isBlurClass = (c: string, s:string) => {
      if(c == state.color && s == state.size) {
        return ""
      } else {
        return "blur-selection"
      }
    }

    const syncSelectApplicates = (arr: any) => {
      state.selectedApplicates = arr
    }

    const isAlreadyBlurClass = (s:string, c:string) => {
      const n = state.size_stock[c][s]
      var count = 0

      for(const inf of all_applicates.value) {
        if(inf.size == s && inf.color == c && inf.applicate_state == "applied") {
          count += 1
        }
      }
      return (n == count)? "blur-selection" : ""
    }


    onMounted(async () => {
      store.dispatch('applicate/fetchApplicates', {job_id: state.job_uuid})
      store
        .dispatch('job/fetchJob', state.job_uuid)
        .then(() => {
          if(job.value["is_size"] == true || job.value["is_variation"] == true) {
            state.size_stock  = JSON.parse(product.value["variation_color_stock_json"])
          }
        })

      state.size = route.query.size
      state.color = route.query.color
      store.dispatch('job/fetchApplicates', {job_id: state.job_uuid, size: state.size, color: state.color})
      
    })
    return {
      job, product, router, state, applicates, updateInfluencers, isCheck, confirmInfluencers, syncSelectApplicates, isBlurClass, definitionInfluencer, isAlreadyBlurClass
    }
  }
})
