
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { useRoute } from 'vue-router'
import Header from "@/components/common/Header.vue"
import ConfirmationJob from "@/components/jobs/ConfirmationJob.vue"
import ProgressBar from "@/components/jobs/ProgressBar.vue"
import RecruitInfluencerTable from "@/components/jobs/RecruitInfluencerTable.vue"

interface State {
  state: string;
  job_uuid: string | string[];
}

export default defineComponent({
  components: {Header, ConfirmationJob, ProgressBar, RecruitInfluencerTable},
  setup () {
    const store = useStore()
    //const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)
    const applicates = computed(() => store.state.job.applicates)

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"]
    })
    const dateDiff = (j: any) => {
      return moment(j.application_end_datetime).diff(moment(), 'days')
    }

    const jobState = () => {
      //var n = moment("2021-10-15")
      var n = moment()
      var application_s_at = moment(job.value.application_start_datetime)
      var application_e_at = moment(job.value.application_end_datetime)
      if (n.isBetween(application_s_at, application_e_at)) {
        state.state = "recruiting"
      } else if (n.isAfter(application_e_at)) {
        state.state = "selecting"
      } else if (n.isBefore(application_s_at)) {
        state.state = "waiting"
      }
    }
    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
        .then(() => {
          jobState()
        })
    })
    return {
      jobState, job, applicates, product, dateDiff
    }
  }
})
