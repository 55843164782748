
import { defineComponent, onMounted } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    job: Object,
  },
  setup () {
    const dateFormat = (d:string) => {
      return moment(d).format("Y/M/D")
    }
    onMounted(async () => {})

    return {dateFormat}
  }
})
