export default Object.freeze({
  ORG: {
    UNDER_REVIEW: "under_review",
    REJECTED: "rejected",
    COMPLETE_CANCELLATION: "complete_cancellation",
    USE_BILLING_PLAN: "use_billing_plan",
    USE_FREE_PLAN: "use_free_plan",
    UNDER_STOP_PLAN: "under_stop_plan",
  },
  JOB: {
    UNDER_REVIEW: "under_review",
    REJECTED: "rejected",
    DRAFT: "draft",
    PASSED: "passed",
    PUBLISHED: "published",
    SELECTED: "selected",
    COMPLETED: "completed",
  },
})
