
import { defineComponent, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Header from "@/components/common/Header.vue"

interface State {
  state: string;
  job_uuid: string | string[];
}

export default defineComponent({
  components: {Header},
  setup () {
    const store = useStore()
    //const router = useRouter()
    const route = useRoute()

    const job = computed(() => store.state.job.job)
    const product = computed(() => store.state.job.product)

    const state = reactive<State>({
      state: "",
      job_uuid: route.params["job_uuid"]
    })
    onMounted(async () => {
      store.dispatch('job/fetchJob', state.job_uuid)
    })
    return {
      job, product 
    }
  }
})
