
import { defineComponent, onMounted, reactive, watch } from 'vue'

interface State {
  imageOption: string[];
  textOption: string[];
  mustHashtag: string[];
  selectHashtag: string[];
  mHashtag: string;
  mHashtag2: string;
  instagramPostNum: number;
}

export default defineComponent({
  props: {
    job: Object,
  },
  setup (props, context) {

    const state = reactive<State>({
      imageOption: [],
      textOption: [],
      mustHashtag: ['ouvo', 'pr'],
      selectHashtag: [],
      mHashtag: "",
      mHashtag2: "",
      instagramPostNum: 0
    })

    const selectNewLists = [
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
    ]
    const hashtagLists = [
      {label: "#pr", type: "input"},
      {label: "#ouvo", type: "input"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
    ]
    const instagramOptionSelection = [
      {label: "ロゴ・商品パッケージを必ず入れてください", type: "normal", prefix: ""},
      {label: "実際に使用しているシーンを撮影ください", type: "normal", prefix: ""},
      {label: "顔出し必須でお願いします", type: "normal", prefix: ""},
      {label: "商品の色味がわからなくなる画像補正はしないでください", type: "normal", prefix: ""},
      {label: "動画投稿必須", type: "normal", prefix: ""},
      {label: "フリー指示", type: "input", prefix: "", i_val: "", placeholder: "競合商品やブランドが写り込まないようにしてください"},
      {label: "フリー指示", type: "input", prefix: "", i_val: "", placeholder: "商品のテクスチャーが分かる写真を入れてください"},
      {label: "フリー指示", type: "input", prefix: "", i_val: "", placeholder: "商品は外装(箱など)から取り出した状態で撮影ください"},
    ]
    const instagramCaptionSelection = [
      {label: "実際に使用しているシーンを撮影ください", type: "normal", prefix: ""},
      {label: "顔出し必須でお願いします", type: "normal", prefix: ""},
      {label: "商品の色味がわからなくなる画像補正はしないでください", type: "normal", prefix: ""},
      {label: "商品名を記載してください", type: "normal", prefix: ""},
      {label: "フリー指示", type: "input", prefix: "", i_val: "", placeholder: "他社製品との比較やネガティブな発言は控えてください"},
      {label: "フリー指示", type: "input", prefix: "", i_val: "", placeholder: "他社製品との比較やネガティブな発言は控えてください"},
    ]

    const changeOpt = (t:string, opt:string[], s:string) => {
      if(opt.indexOf(s) == -1) {
        opt.push(s)
      } else {
        opt.splice(opt.indexOf(s), 1)
      }
      if(t == 'image') {
        context.emit('updateImageOpt', opt)
      } else if(t == 'caption') {
        context.emit('updateCapOpt', opt)
      } else if(t == 'must_hash') {
        context.emit('updateMustHash', opt)
      } else if(t == 'hash') {
        context.emit('updateRecommendHash', opt)
      }
    }

    const updateReccomendHashtagInput = (l:string, i:number) => {
      const t = l.replace('#', '')
      state.selectHashtag[i] = t
      context.emit('updateRecommendHash', state.selectHashtag)
    }
    const updateCaption = (t: string, opt:string[], l:string, i:number) => {
      opt[i] = l
      if(t == 'image') {
        context.emit('updateImageOpt', opt)
      } else if(t == 'caption') {
        context.emit('updateCapOpt', opt)
      }
    }
    const updateMustHashtagInput = () => {
      const t = state.mHashtag.replace('#', '').replace(' ', '')
      const t2 = state.mHashtag2.replace('#', '').replace(' ', '')
      state.mustHashtag = ['ouvo', 'pr', t, t2]
      context.emit('updateMustHash', ['ouvo', 'pr', t, t2])
    }

    const changePostNum = () => {
      context.emit('updatePostNum', state.instagramPostNum)
    }

    const isCheck = (opt:string[], s:string) => {
      if(s == "") {
        return false
      }
      if(opt.indexOf(s) == -1) {
        return false
      } else {
        return true
      }     
    }
    const castArray = (s: string) => {
      if(s == "") {
        return []
      } else {
        return s.split(",")
      }
    }
    const isAvailableFree = (i:number, opt:string[]) => {
      if(opt == undefined) {
        return false
      }
      if(opt.length >= i) {
        return false
      } else {
        return true
      }
    }
    const isAvailable = (i:number) => {
      const l = state.selectHashtag.length 
      if(l >= i) {
        return false
      } else {
        return true
      }
    }
    watch(props, () => {
      state.imageOption = castArray(props.job?.instagram_caution_image)
      state.textOption = castArray(props.job?.instagram_caution_caption)
      state.mustHashtag = castArray(props.job?.must_hashtags)
      if(state.mustHashtag.length == 0) {
        state.mustHashtag = ['ouvo', 'pr']
        state.mHashtag = ""
        context.emit('updateMustHash', ['ouvo', 'pr'])
      } else {
        state.mustHashtag = props.job?.must_hashtags.split(",")
        state.mHashtag = state.mustHashtag[2] || ""
        state.mHashtag2 = state.mustHashtag[3] || ""

        context.emit('updateMustHash', castArray(props.job?.must_hashtags))
      }
      state.selectHashtag = castArray(props.job?.recommend_hashtags)
      state.instagramPostNum = props.job?.instagram_post_num

      state.selectHashtag.forEach((h,i) => {
        selectNewLists[i]["label"] = h
      })
    })

    onMounted(() => {
      console.log(state)
    })
    return {
      instagramOptionSelection, hashtagLists, selectNewLists, state, changeOpt, isCheck, instagramCaptionSelection, changePostNum, castArray, updateMustHashtagInput,
      updateReccomendHashtagInput,isAvailable, updateCaption, isAvailableFree
    }

  }
})
