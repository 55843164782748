
import { defineComponent, onMounted, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Header from "@/components/common/Header.vue"
import SideBar from "@/components/common/SideBar.vue"

export default defineComponent({
  components: {Header, SideBar},
  setup () {
    const auth = inject<any>('$auth')
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user.user)
    const org = computed(() => store.state.user.organization)

    onMounted(async () => {
      store.dispatch('user/fetchUser')
    })
    const cancel = () => {
      router.push({ name: "Settings" })
    }

    const onFileChange = (e:any) => {
      const files = e.target.files || e.dataTransfer.files;
      const reader = new FileReader();
      reader.onload = (f) => {
        console.log(e)
        org.value.logo_img = f?.target?.result;
      };
      reader.readAsDataURL(files[0]);
    }
    //const onFileChange = (e:any) => {
    //  const files = e.target.files[0] || e.dataTransfer.files[0];
    //  if(state.job.images == null) {
    //    state.job.images = []
    //  }
    //  const sizeLimit = 1024 * 1024 * 3;

    //  for(const f in files) {
    //    const reader = new FileReader();
    //    reader.onload = (e:any) => {
    //      state.job.images.push(e.target.result);
    //    };

    //    if (files[f].size > sizeLimit) {
    //      Swal.fire({ title: '入力エラー', text: "ファイルサイズは3MB以下にしてください", icon: 'error', confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'OK'})
    //      return; 
    //    } else {
    //      reader.readAsDataURL(files[f]);
    //    }
    //  }
    //}
    const onSubmitUpdate = () => {
      store.dispatch('user/updateUser')
        .then(() => {
          store.dispatch('user/updateOrganization')
          router.push({ name: "Settings" })
        })
    }
    const logout = () => {
      auth.logout({
        returnTo: window.location.origin,
      })
    }
    return {
      logout, user, org, onSubmitUpdate, cancel, onFileChange
    }
  }
})

