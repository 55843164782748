import { createStore } from "vuex"
import axios from 'axios'


const state ={
  user: {},
  organization: {},
  plan: {}
}
const actions = {
  fetchUser({commit}: {commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    return axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/organizations/1", {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
        console.log(res.data.staff)
        commit("setUser", res.data.staff)
        commit("setOrganization", res.data.org)
        commit("setPlan", res.data.history)
      })
      .catch((err) => {
        commit("setUser", err.response.data.staff)
      })

  },
  callbackUser({commit}: {commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios.get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/organizations/1", headers)
  },
  updateUser({state, commit}: {state: any, commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/staffs/" + state.user.id, {staff: state.user}, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
        commit("setUser", res.data)
      })
  },
  updateOrganization({commit}: {commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/organizations/1", {organization: state.organization}, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
      .then((res: any) => {
        commit("setOrganization", res.data)
      })
  },

}
const mutations = {
  setUser(state: any, user: object) {
    state.user = user
  },
  setOrganization(state: any, org: object) {
    state.organization = org
  },
  setPlan(state: any, plan: object) {
    state.plan = plan || {start_at: "2022/03/02"}
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

