
import { createStore } from "vuex"
import axios from 'axios'

const state ={
  products: [],
  product: {},
}
const actions = {
  //fetchProducts({commit}: {commit: any}) {
  //  const idToken: string | null = localStorage.getItem("idToken")
  //  axios
  //    .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs", {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
  //    .then((res: any) => {
  //      commit("setJobs", res.data.jobs)
  //    })
  //},
  //fetchProduct({commit}: {commit: any}, job_id:string) {
  //  const idToken: string | null = localStorage.getItem("idToken")
  //  axios
  //    .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/products/" + job_id, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
  //    .then((res: any) => {
  //      commit("setJob", res.data.job)
  //      commit("setApplicates", res.data.applicates)
  //    })
  //},
  updateProduct({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_id + "/products/" + payload.id, {product: payload}, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})
  },
  createProduct({commit}: {commit: any}, payload:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    return axios
      .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/jobs/" + payload.job_id + "/products", {product: payload}, {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}})

  }
}
const mutations = {
  //addStaff(state: any, staff: object) {
  //  state.staffs.push(staff)
  //}
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

