
import { createStore } from "vuex"
import axios from 'axios'


const state ={
  card_tokens: [],
  card_token: {}
}
const actions = {
  fetchCardTokens({commit}: {commit: any}) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + "/api/v2/card_tokens", headers)
      .then((res: any) => {
        commit("setCardTokens", res.data.card_tokens)
      }).catch((err) => {
        if (err.response.status == 301) {
          //this.$router.push({ name: "GettingStarted" })
          console.log("----")
          console.log("301")
        }
      })
  },
  updateCardToken({state, commit}: {state: any, commit: any}, id:string) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .patch(process.env.VUE_APP_API_ENDPOINT + "/api/v2/card_tokens/" + id, headers)
      .then((res: any) => {
        commit("setCardToken", res.data)
      })
  },
  deleteCardToken({state, commit}: {state: any, commit: any}, id:string) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .delete(process.env.VUE_APP_API_ENDPOINT + "/api/v2/card_tokens/" + id, headers)
  },
  createCardToken({state, commit}: {state: any, commit: any}, obj:any) {
    const idToken: string | null = localStorage.getItem("idToken")
    const headers = {headers: { Authorization: `Bearer ${idToken}`, "Content-Type": "application/json"}}
    return axios
      .post(process.env.VUE_APP_API_ENDPOINT + "/api/v2/card_tokens", {card_token: obj}, headers)
      .then(() => {
        //commit("addCardToken", res.data.card_token)
      })
  },
}
const mutations = {
  setCardTokens(state: any, card_tokens: any) {
    state.card_tokens = card_tokens
  },
  setCardToken(state: any, card_token: object) {
    state.card_token = card_token
  },
  addCardToken(state: any, card_token: object) {
    state.card_tokens.append(card_token)
  }
}
const getters = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

