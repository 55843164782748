
import { defineComponent, onMounted, reactive, watch } from 'vue'

interface State {
  storyOption: string[];
  selectHashtag: string[];
  mustHashtag: string[];
  mHashtag: string;
  instagramStoryPostNum: number;
}



export default defineComponent({
  props: {
    job: Object,
  },
  setup (props, context) {
    const state = reactive<State>({
      storyOption: [],
      mustHashtag: ['mereco'],
      selectHashtag: [],
      mHashtag: "",
      instagramStoryPostNum: 0
    })

    const selectNewLists = [
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
      {label: "", type: "new"},
    ]
    const hashtagLists = [
      {label: "#mereco", type: "input"},
      {label: "", type: "new"},
    ]
    const instagramOptionSelection = [
      {label: "弊社アカウントをメンションしてください", type: "normal", prefix: ""},
      {label: "ロゴ・商品パッケージを必ず入れてください", type: "normal", prefix: ""},
      {label: "実際に使用しているシーンを撮影ください", type: "normal", prefix: ""},
      {label: "顔出し必須でお願いします", type: "normal", prefix: ""},
      {label: "商品の色味がわからなくなる画像補正はしないでください", type: "normal", prefix: ""},
      {label: "動画投稿必須", type: "normal", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
      {label: "フリー指示", type: "input", prefix: ""},
    ]
    const changePostNum = () => {
      context.emit('updatePostNum', state.instagramStoryPostNum)
    }

    const changeOpt = (t:string, opt:string[], s:string) => {
      if(opt.indexOf(s) == -1) {
        opt.push(s)
      } else {
        opt.splice(opt.indexOf(s), 1)
      }
      if(t == 'story') {
        context.emit('updateStoryOpt', opt)
      } else if(t == 'must_hash') {
        context.emit('updateMustHash', opt)
      } else if(t == 'hash') {
        context.emit('updateReccomendHash', opt)
      }
    }
    const isCheck = (opt:string[], s:string) => {
      if(opt.indexOf(s) == -1) {
        return false
      } else {
        return true
      }     
    }
    const isAvailable = (i:number) => {
      const l = state.selectHashtag.length 
      if(l >= i) {
        return false
      } else {
        return true
      }
    }
    const updateReccomendHashtagInput = (l:string, i:number) => {
      const t = l.replace('#', '')
      state.selectHashtag[i] = t
      context.emit('updateRecommendHash', state.selectHashtag)
    }
    const updateMustHashtagInput = () => {
      const t = state.mHashtag.replace('#', '')
      state.mustHashtag = ['mereco', t]
      context.emit('updateMustHash', ['mereco', t])
    }

    watch(props, () => {
      state.storyOption = castArray(props.job?.instagram_cation_story)
      state.mustHashtag = castArray(props.job?.must_story_hashtags)
      if(state.mustHashtag.length == 0) {
        state.mustHashtag = ['mereco']
        state.mHashtag = ""
        context.emit('updateMustHash', ['mereco'])
      } else {
        state.mustHashtag = props.job?.must_story_hashtags.split(",")
        state.mHashtag = state.mustHashtag.slice(-1)[0]

        context.emit('updateMustHash', castArray(props.job?.must_story_hashtags))
      }
      state.instagramStoryPostNum = props.job?.instagram_story_post_num
      state.selectHashtag = castArray(props.job?.recommend_story_hashtags)
      state.selectHashtag.forEach((h,i) => {
        selectNewLists[i]["label"] = h
      })
    })
    const castArray = (s: string) => {
      if(s == "" || s == null) {
        return []
      } else {
        return s.split(",")
      }
    }

    onMounted(async () => {})
    return {
      instagramOptionSelection, hashtagLists, selectNewLists, state, changeOpt, isCheck, castArray,
      updateMustHashtagInput, updateReccomendHashtagInput, isAvailable, changePostNum
    }
  }
})
